::-webkit-scrollbar {
	display: unset !important;
    width: 12px;
	/* border-left: px solid rgba(128, 128, 128, 0.144); */
	background-color: rgba(128, 128, 128, 0.144);
}     
::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
    -webkit-border-radius: 10px;
    border-radius: 10px;
}     
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 6px;
    background: rgba(128, 128, 128, 0.452); 
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
}
::-webkit-scrollbar-thumb:window-inactive {
    /* background: rgba(255,0,0,0.4);  */
}