#root .pcView{
  display: block;
}
#root .mbView{
  display: none;
}

/* fullpage */
#fp-nav.right {
  right: 44px;
  transition: all 0.7s;
}
#fp-nav ul {
  display: flex;
  gap: 4px;
  flex-direction: column;
}
#fp-nav ul li {
  width: 24px;
  height: 24px;
  margin: unset;
}
#fp-nav ul li:last-child {
  display: none;
}
#fp-nav ul li a {
  display: flex;
  position: unset;
  justify-content: center;
  align-items: center;
}
#fp-nav ul li a span {
  background-color: #e4e4e4;
  width: 8px;
  height: 8px;
  position: unset;
  left: unset;
  top: unset;
  margin: unset;
}
#fp-nav ul li a.active {
  display: flex;
  position: unset;
  border-radius: 100%;
  border: 1px solid #09abbe;
}
#fp-nav ul li a.active span {
  background-color: #09abbe;
  width: 8px;
  height: 8px;
  position: unset;
  left: unset;
  top: unset;
  margin: unset;
}
#fp-nav ul li:hover a span {
  margin: unset;
  width: 8px;
  height: 8px;
}
#fp-nav ul li:hover a.active span {
  width: 8px;
  height: 8px;
  margin: unset;
}
.fp-section {
  display: block;
}
.fp-tableCell {
  display: block;
  position: relative;
}

@media screen and (max-width: 768px) {
  #root .pcView{
    display: none;
  }
  #root .mbView{
    display: block;
  }
  #fp-nav.right {
    right: 20px;
    top: 145px;
  }
}
/* Slick */
/* .slick-list { height: 100%; }
.slick-track { height: 100%; } */

/*====================================================================
    main
======================================================================*/
/* 쿠카팝업 */
.main .popupWrap {
  display: none;
}
.main .popupWrap.open {
  display: block;
}
.main .popup {
  position: fixed;
  left: 50%;
  top: calc(100vh / 2);
  transform: translate(-50%, -50%);
  max-width: calc(100% - 40px);
  max-height: calc(100vh - 160px);
  background-color: #333;
  z-index: 9999;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  /* height: 500px; */
  /* overflow: auto; */
  width: 500px;
}
.main .popup.none {
  display: none;
}
.main .popup .popup-btn-wrap {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: -30px;
  z-index: 9999;
  background: #333;
}
.main .popup .popup-btn-wrap label {
  display: flex;
  color: #fff;
  gap: 8px;
  padding: 0 8px;
}
.main .popup .popup-btn-wrap .close {
  color: #fff;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: #333;
  height: 100%;
}
.main .popup .img-wrap {
  cursor: pointer;
  width: 100%;
  position: relative;
  max-height: calc(100vh - 160px);
}
.main .popup .img-wrap::before {
  content: "";
  width: 100%;
  display: block;
  padding-top: 130%;
}
.main .popup .img-wrap img {
  width: 100%;
  height: 100%;
  object-position: center;
  /* object-fit: contain;  */
  position: absolute;
  left: 0;
  top: 0;
}
.main .popup .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
}

/* section1*/
.main .section1 .slick-slider {
  height: 100%;
}
.main .section1 .slick-slide {
  z-index: -1;
}
.main .section1 .slick-slide.slick-active {
  z-index: 1;
}
.main .section1 .slick-dots {
  display: flex;
  gap: 16px;
  width: auto;
  left: 40px;
  bottom: 40px;
}
.main .section1 .slick-dots li {
  margin: unset;
  width: auto;
  height: auto;
}
.main .section1 .slick-dots li > div {
  display: flex;
  align-items: center;
  margin: unset;
  font-size: 17px;
  width: unset;
  height: unset;
  color: #fff;
  font-weight: 500;
}
.main .section1 .slick-dots li > div .bar {
  width: 0;
  height: 2px;
  background-color: #ffffff80;
  position: relative;
}
.main .section1 .slick-dots li > div .bar span {
  display: flex;
  position: absolute;
  height: 100%;
  width: 0;
  background-color: #fff;
}
.main .section1 .slick-dots li.slick-active > div .bar {
  width: 120px;
  margin-left: 16px;
}
.main .section1 .slick-dots li.slick-active > div .bar span {
  width: 100%;
  transition: all 7s;
}
.main .section1 .slick-control {
  display: flex;
  gap: 8px;
  height: 17px;
  position: absolute;
  bottom: 40px;
  z-index: 99;
  margin-left: 40px;
}
.main .section1 .slick-control i {
  font-size: 17px;
  color: #fff;
}

.main .section1 .slide-item {
  width: 100%;
  height: 100vh;
  position: relative;
}
.main .section1 .slide-item .slide-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.main .section1 .slide-item .slide-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.main .section1 .slide-item .slide-bg video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.main .section1 .slide-item .container {
  height: 100%;
  position: relative;
}
.main .section1 .slide-item .txt-wrap {
  position: absolute;
  top: 50%;
  left: 120px;
  transform: translateY(-100%);
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.main .section1 .slide-item .txt-top {
  z-index: 1;
}
.main .section1 .slide-item .txt-top p {
  font-size: 64px;
  font-weight: 600;
  font-family: "SCoreDream";
  line-height: 1.43;
  color: #fff;
  white-space: break-spaces;
}
.main .section1 .slide-item .txt-bt {
  z-index: 1;
}
.main .section1 .slide-item .txt-bt p {
  font-size: 32px;
  letter-spacing: -0.5px;
  color: #fff;
  opacity: 0.5;
  white-space: break-spaces;
}
.main .section1 .slide-item .txt-wrap::before {
  position: absolute;
  content: "";
  width: 1100px;
  height: 1100px;
  background-image: url(./images/main-bg.svg);
  bottom: -377px;
  left: -295px;
  z-index: 0;
  background-size: cover;
  opacity: 0.8;
}

/* section2 */
.main .section2 {
  background: linear-gradient(90deg, #f5f5f5 50%, #ffffff 50%);
}
.main .section2 .container {
  background-color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
}
.main .section2 .container::after {
  position: absolute;
  right: 0;
  top: 0;
  width: 639px;
  height: 1106px;
  background-image: url(./images/main-st2-bg.png);
  content: "";
  transform: translateX(50%);
  z-index: 0;
}
.main .section2 .img-wrap {
  width: 50%;
  background-color: #fff;
  border-radius: 100%;
  position: relative;
  padding: 40px;
  transform: translateX(-80px);
}
.main .section2 .img-wrap::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.main .section2 .img-wrap::after {
  content: "";
  position: absolute;
  top: 5.5%;
  right: 5.5%;
  bottom: 5.5%;
  left: 5.5%;
  background-image: url(./images/circle-move.png);
  background-size: cover;
  animation: rotate_image 25s linear infinite;
  transform-origin: 50% 50%;
}
@keyframes rotate_image {
  100% {
    transform: rotate(360deg);
  }
}
.main .section2 .img-wrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 81.5%;
  height: 81.5%;
  border-radius: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
}
.main .section2 .txt-wrap {
  z-index: 1;
  width: 50%;
}
.main .section2 .txt-top {
  margin-bottom: 34px;
}
.main .section2 .txt-top p {
  font-family: "SCoreDream";
  font-size: 40px;
  line-height: 1.4;
  color: #09abbe;
  letter-spacing: -1px;
  font-weight: 600;
}
.main .section2 .txt-top p span {
  font-family: "SCoreDream";
  font-weight: 600;
  color: #09abbe;
}
.main .section2 .txt-ct {
  margin-bottom: 32px;
}
.main .section2 .txt-ct p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: -0.7px;
  color: #232323;
}
.main .section2 .txt-bt {
  margin-bottom: 48px;
}
.main .section2 .txt-bt p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  line-height: 1.5;
  color: #232323;
}
.main .section2 .btn-st1 {
  margin: unset;
}

/* section3 */
.main .section3 .fp-tableCell {
  display: flex;
  justify-content: flex-end;
}
.main .section3 .st3-wrap {
  width: calc(100% - ((100vw - 1280px) / 2));
  max-width: 100%;
  height: 100%;
  border-left: 1px solid #e4e4e4;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main .section3 .st3-wrap .txt-wrap {
  margin-bottom: 80px;
}
.main .section3 .st3-wrap .txt-wrap .txt-top {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -1px;
  color: #09abbe;
  margin-bottom: 14px;
  position: relative;
}
.main .section3 .st3-wrap .txt-wrap .txt-top::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background-image: url(./images/main-bg3.png);
  background-position: center;
  background-size: cover;
  left: -60px;
}
.main .section3 .st3-wrap .txt-wrap .txt-bt {
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #aaa;
}
.main .section3 .slick-track {
  display: flex;
  min-height: 473px;
}
.main .section3 .slider-wrap {
  width: 100%;
  position: relative;
}
.main .section3 .slider-wrap .slick-slider {
  width: calc(100% + ((100% / 4) / 2));
}
.main .section3 .slick-slide > div {
  margin-right: 40px;
}
.main .section3 .slide-item {
  display: flex;
  border-bottom: 1px solid #e4e4e4;
  transition: all 0.5s;
}
.main .section3 .slide-item .img-wrap {
  width: 100%;
  height: 264px;
  border-radius: 16px;
  border: solid 1px #d0d5de;
  overflow: hidden;
  transition: all 0.5s;
}
.main .section3 .slide-item .img-wrap img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.main .section3 .slide-item .content-wrap {
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.main .section3 .slide-item .content-wrap a {
  width: 100%;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 500;
  color: #110d0c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.3s;
}
.main .section3 .slide-item .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main .section3 .slide-item .content .classify {
  display: flex;
  gap: 10px;
  width: calc(100% - 19px);
}
.main .section3 .slide-item .content span {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d0d5de;
  border-radius: 100%;
}
.main .section3 .slide-item .content span i {
  color: #fff;
}
.main .section3 .slide-item .content p {
  color: #d0d5de;
  width: calc(100% - 34px - 19px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main .section3 .slide-item .content i {
  font-size: 19px;
  color: #aaa;
  cursor: pointer;
  transition: all 0.3s;
}
.main .section3 .slick-current .slide-item {
  border-bottom: 2px solid #09abbe;
}
.main .section3 .slick-current .img-wrap {
  height: 331px;
}
.main .section3 .slide-item:hover .content-wrap a {
  color: #09abbe;
}
.main .section3 .slide-item:hover .content > i {
  color: #09abbe;
}
.main .section3 .slick-arrow:before {
  display: none;
}
.main .section3 .slider-wrap .btn-wrap {
  position: absolute;
  display: flex;
  gap: 24px;
  bottom: calc(100% + 80px);
  right: calc((100vw - 1280px) / 2);
}
.main .section3 .slider-wrap .btn-wrap button {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: solid 2px #f5f5f5;
  background-color: #f5f5f5;
}
.main .section3 .slider-wrap .btn-wrap button i {
  color: #d0d5de;
  font-size: 43px;
}
#fp-nav.top {
  top: 20%;
}
@media screen and (max-width: 1280px) {
  .main .section3 .slider-wrap .btn-wrap {
    right: 16px;
  }
}
/* section4 */
.main .section4 {
  background-color: #f5f5f5;
}
.main .section4 .container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
}
.main .section4 h3 {
  font-size: 40px;
  font-weight: 600;
}
.main .section4 h3 span {
  font-weight: 600;
  color: #09abbe;
}
.main .section4 .slide-wrap {
  width: 100%;
  position: relative;
}
.main .section4 .slick-slider {
  width: 100%;
}
.main .section4 .slick-track {
  display: flex;
  gap: 24px;
}
.main .section4 .slide-item {
  padding: 40px 32px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  transition: all 0.3s;
  position: relative;
}
.main .section4 .slide-item > a {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #09abbe;
  position: absolute;
  right: 24px;
  bottom: 24px;
  transition: all 0.3s;
}
.main .section4 .slide-item > a i {
  font-size: 19px;
  color: #fff;
  transition: all 0.3s;
}
.main .section4 .slide-item:hover a {
  background-color: #fff;
}
.main .section4 .slide-item:hover a i {
  color: #09abbe;
}
.main .section4 .flex-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.main .section4 .slide-item .txt-top {
  width: 100%;
}
.main .section4 .slide-item .txt-top p {
  width: 100%;
  line-height: 1.6;
  min-height: calc(1.6em * 3);
  font-size: 20px;
  font-family: "SCoreDream";
  letter-spacing: -0.5px;
  font-weight: 500;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}
.main .section4 .slide-item .txt-ct {
  width: 100%;
}
.main .section4 .slide-item .txt-ct p {
  width: 100%;
  line-height: 1.5;
  min-height: calc(1.5em * 4);
  letter-spacing: -0.4px;
  font-weight: 300;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}
.main .section4 .flex-wrap > span {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: -0.38px;
  color: #b3b2b2;
}
.main .section4 .slide-item:hover {
  background-color: #09abbe;
}
.main .section4 .slide-item:hover .txt-top p {
  color: #fff;
}
.main .section4 .slide-item:hover .txt-ct p {
  color: #fff;
  opacity: 0.6;
}
.main .section4 .slide-item:hover .flex-wrap > span {
  color: #fff;
  opacity: 0.5;
}
.main .section4 .sl-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 56px;
  height: 56px;
  border-radius: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  transition: all 0.3s;
}
.main .section4 .sl-button i {
  font-size: 24px;
  transition: all 0.3s;
}
.main .section4 .sl-button:hover {
  background-color: #09abbe;
}
.main .section4 .sl-button:hover i {
  color: #fff;
}
.main .section4 .left-btn {
  right: calc(100% + 24px);
}
.main .section4 .right-btn {
  left: calc(100% + 24px);
}
.main .section4 .add-btn {
  width: 218px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.13);
  border: solid 2px #09abbe;
  background-color: #f5f5f5;
  color: #09abbe;
  transition: all 0.3s;
  font-weight: bold;
}
.main .section4 .add-btn:hover {
  background-color: #09abbe;
  color: #fff;
}

/* section5 */
.main .section5 {
  background-image: url(./images/inquiry-bg.jpg);
  background-position: center;
  background-size: cover;
}
.main .section5 .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.main .section5 h3 {
  font-size: 48px;
  font-weight: 600;
  color: #09abbe;
  margin-bottom: 80px;
}
.main .section5 .flex-box {
  width: 100%;
  display: flex;
  gap: 40px;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 56px;
}
.main .section5 .flex-box > div {
  width: calc((100% - 40px) / 2);
  max-width: 540px;
}
.main .section5 .top-wrap {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
}
.main .section5 .top-wrap span {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: #09abbe;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.main .section5 .top-wrap p {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
}
.main .section5 .question-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.main .section5 .question-wrap textarea {
  width: 100%;
  height: 274px;
  outline: unset;
  padding: 16px;
  border-radius: 8px;
}
.main .section5 .question-wrap .ck-wrap {
  width: 100%;
  height: 56px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main .section5 .question-wrap .ck-wrap a {
  display: flex;
  gap: 16px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  color: #bcbcbc;
}
.main .section5 .question-wrap .ck-wrap a i {
  color: #bcbcbc;
  font-size: 14px;
}
.warning {
  opacity: 0;
  text-align: center;
  color: red;
  margin-top: 24px;
}
.warning.on {
  opacity: 1;
}

.main .section5 .inquire-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 218px;
  height: 64px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.13);
  border-radius: 32px;
  background-color: #09abbe;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}
.main .footer #upBtn {
  display: none;
}

.layerPopup {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
}
.layerPopup.none {
  display: none;
}
.layerPopup .layerBox {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #555;
  margin: 10px;
  width: 500px;
}
.layerPopup .layerBox .mySwiperpop {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.layerPopup .layerBox .swiper-slide a {
  display: block;
  width: 100%;
  height: 100%;
}
.layerPopup .layerBox .swiper-slide a img {
  width: 100%;
  height: 100%;
}
.layerPopup .layerBox .swiper-pagination-bullet-active {
  background-color: #fff;
}
.layerPopup .layerBox .btn-wrap {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.layerPopup .layerBox .btn-wrap a {
  color: #fff;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.layerPopup .layerBox .btn-wrap a:last-child {
  background-color: #333;
}
@media screen and (max-width: 1000px) {
  .layerPopup .layerBox1 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 500px) {
  .layerPopup {
    width: 100%;
  }
  .layerPopup .layerBox {
    width: 100%;
    max-width: calc(100% - 20px);
  }
}

@media screen and (max-width: 1048px) {
  .main .section1 .slide-item .txt-wrap {
    left: 32px;
  }
  .main .section3 .slider-wrap .slick-slider {
    width: 100%;
  }
  .main .section3 .slick-current .img-wrap {
    height: 280px;
  }
  .main .section3 .slide-item .img-wrap {
    height: 280px;
  }
  .main .section3 .slide-item {
    border-bottom: 2px solid #09abbe;
  }
  .main .section3 .st3-wrap .txt-wrap .txt-top {
    font-size: 28px;
  }
  .main .section3 .st3-wrap .txt-wrap .txt-bt {
    font-size: 12px;
    width: calc(100% - 50px);
  }
  .main .section3 .st3-wrap .txt-wrap {
    margin-bottom: 96px;
  }
  .main .section3 .slider-wrap .btn-wrap {
    right: 20px;
    bottom: calc(100% + 24px);
    gap: 8px;
  }
  .main .section3 .slider-wrap .btn-wrap button {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
  .main .section3 .slider-wrap .btn-wrap button i {
    font-size: 18px;
  }
  .main .section3 .slide-item .content-wrap {
    padding: 24px;
  }
  .main .section3 .slide-item .content-wrap a {
    font-size: 16px;
  }
  .main .section3 .slide-item .content p {
    font-size: 12px;
  }
  .main .section3 .st3-wrap .txt-wrap .txt-top::before {
    width: 32px;
    height: 32px;
    left: 0;
    bottom: calc(100% + 16px);
  }
  .main .section3 .slide-item .content i {
    color: #09abbe;
  }
  .main .section3 .st3-wrap {
    padding: 0 20px;
  }
  .main .section3 .slick-track {
    min-height: unset;
  }
}

@media screen and (max-width: 768px) {
  .header.active.no-bg {
    background-color: #fff;
  }
  #fp-nav.top {
    top: 145px;
  }
  .main .section1 .slick-control {
    display: none;
  }
  .main .section1 .slide-item .txt-wrap {
    left: 24px;
    gap: 16px;
    top: 40%;
  }
  .main .section1 .slide-item .txt-wrap::before {
    width: 500px;
    height: 500px;
    bottom: -172px;
    left: -138px;
  }
  .main .section1 .slide-item .txt-top p {
    font-size: 32px;
  }
  .main .section1 .slide-item .txt-bt p {
    font-size: 16px;
  }
  .main .section2 .container::after {
    display: none;
  }
  .main .section2 .img-wrap {
    min-width: 289px;
    transform: translate(-80px, -40px);
  }
  .main .section2 .txt-wrap {
    width: 100%;
  }
  .main .section2 .container {
    flex-direction: column;
    align-items: unset;
    padding-top: 15%;
  }
  .main .section3 .st3-wrap {
    padding: 56px 20px 0;
  }
  .main .section4 h3 {
    font-size: 28px;
    margin-bottom: 40px;
  }
  .main .section4 .container {
    gap: unset;
  }
  .main .section4 .slide-wrap {
    margin-bottom: 60px;
  }
  .main .section4 .add-btn {
    width: 160px;
    height: 48px;
    border-radius: 24px;
    font-size: 14px;
  }

  .main .section4 .slide-item .txt-top p {
    font-size: 16px;
  }
  .main .section4 .slide-item .txt-ct p {
    font-size: 12px;
  }
  .main .section4 .flex-wrap > span {
    font-size: 15px;
  }

  .main .section5 .container {
    position: relative;
  }

  .main .section5 h3 {
    font-size: 28px;
    margin-bottom: 16px;
  }
  .main .section5 .flex-box {
    flex-direction: column;
  }
  .main .section5 .flex-box > div {
    width: 100%;
    max-width: unset;
  }
  .main .section5 .top-wrap p {
    font-size: 16px;
  }
  .main .section5 .top-wrap {
    margin-bottom: 16px;
  }
  .main .section5 .top-wrap.mb {
    display: none;
  }
  .main .section5 .question-wrap textarea {
    height: 80px;
    border-radius: 4px;
    font-size: 14px;
  }
  .main .section5 .question-wrap textarea::placeholder {
    font-size: 14px;
  }
  .selectBox2 .optionItem {
    height: 40px;
    font-size: 12px;
  }
  .main .section5 .selectBox2 {
    height: 40px;
    border-radius: 4px;
  }
  .main .section5 .selectBox2 .label {
    font-size: 12px;
  }
  .main .section5 .flex-box {
    gap: 16px;
    margin-bottom: 28px;
  }
  .main .section5 .ipt-wrap {
    height: 40px;
  }
  .main .section5 .ipt-wrap span {
    display: none;
  }
  .main .section5 .ipt-wrap input {
    width: 100%;
    font-size: 12px;
  }
  .main .section5 .ipt-wrap input::placeholder {
    font-size: 12px;
  }
  .main .section5 .question-wrap {
    gap: 8px;
  }
  .main .section5 .question-wrap .ck-wrap {
    padding: unset;
    height: auto;
    margin-top: 8px;
  }
  .main .section5 .ck-label p {
    font-size: 12px;
  }
  .main .section5 .ck-label span {
    width: 14px;
    height: 14px;
  }
  .main .section5 .question-wrap .ck-wrap a {
    font-size: 12px;
    gap: 8px;
  }
  .main .section5 .question-wrap .ck-wrap a i {
    font-size: 12px;
  }
  .main .section5 .warning {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .main .section4 .slick-track {
    gap: unset;
  }
}

@media screen and (max-width: 500px) {
  .main .section2 .container {
    padding-top: unset;
    justify-content: center;
    overflow: hidden;
  }
  .main .section2 .txt-top {
    margin-bottom: 24px;
  }
  .main .section2 .txt-top p {
    font-size: 24px;
    letter-spacing: -0.7px;
  }
  .main .section2 .txt-ct p {
    font-size: 14px;
    font-weight: 300;
  }
  .main .section2 .txt-ct p br {
    display: none;
  }
  .main .section2 .txt-ct {
    margin-bottom: 24px;
  }
  .main .section2 .txt-bt p {
    font-size: 10px;
  }
  .main .section2 .txt-bt {
    margin-bottom: 40px;
  }
  .main .section2 .more-btn {
    width: 140px;
    height: 40px;
    border-radius: 20px;
    padding: 0 16px;
  }
  .main .section2 .more-btn p {
    font-size: 11px;
  }
  .main .section2 .more-btn i {
    font-size: 11px;
  }
  .main .section3 .slick-slide > div {
    margin: unset;
  }
}

/* =========================================================================
    sub
===========================================================================*/
.scroll-wrap {
  width: 100%;
  height: max-content;
  overflow: auto;
}
.table-wrap {
  width: 100%;
  min-width: 980px;
}

/* sub-top */
.sub-top .sub-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 27px;
  height: 640px;
  position: relative;
  background-color: #09abbe;
  overflow: hidden;
}
.sub-top .sub-banner h2 {
  font-family: "SCoreDream";
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  z-index: 1;
}
.sub-top .sub-banner p {
  font-weight: 300;
  letter-spacing: -0.4px;
  color: #fff;
  z-index: 1;
}
.sub-top .banner-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-position: center;
  transition: all 0.5s;
}
.sub-top .sub-banner:hover .banner-img {
  width: 105%;
  height: 105%;
}
.sub-top .sub-map {
  height: 56px;
  border-bottom: 1px solid #c9c9c9;
}
.sub-top .sub-map .container {
  height: 100%;
  display: flex;
}
.sub-top .sub-map .home-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 56px;
  background-color: #313131;
}
.sub-top .sub-map .home-btn i {
  font-size: 32px;
  color: #fff;
}
.sub-top .sub-map .sub-menu {
  height: 100%;
  width: 320px;
  position: relative;
  border-right: 1px solid #c9c9c9;
}
.sub-top .sub-map .sub-menu3 {
  display: none;
}
.sub-top .sub-map .sub-menu4 {
  display: none;
}
.sub-top .sub-map .sub-menu .show {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 32px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.sub-top .sub-map .sub-menu .show p {
  letter-spacing: -0.43px;
  font-weight: bold;
  color: #707070;
}
.sub-top .sub-map .sub-menu .show i {
  font-size: 16px;
  color: #707070;
}
.sub-top .sub-map .sub-menu .sub-menu-list {
  position: absolute;
  left: 0;
  top: 100%;
  box-shadow: 3px 3px 10px #00000029;
  z-index: 999;
  width: 100%;
  max-height: 0;
  overflow: hidden;
}
.sub-top .sub-map .sub-menu .sub-menu-list li {
  width: 100%;
  height: 56px;
  background-color: #fff;
  border-bottom: 1px solid #f6f6f6;
}
.sub-top .sub-map .sub-menu .sub-menu-list li a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 32px;
  letter-spacing: -0.4px;
  color: #707070;
}
.sub-top .sub-map .sub-menu.open .sub-menu-list {
  max-height: calc(56px * 5);
  transition: all 0.5s;
}
.mb-sub-menu {
  display: none;
}

/* sub-con */
.h3-st1 {
  text-align: center;
  display: flex;
  gap: 24px;
  font-size: 40px;
  font-family: "SCoreDream";
  align-items: flex-end;
  font-weight: 600;
}
.h3-st1::before {
  content: "";
  display: flex;
  width: 40px;
  height: 40px;
  background-image: url(./images/point.png);
  background-size: cover;
}
.h3-st1 span {
  font-size: 24px;
  font-weight: 500;
  color: #232323;
  font-family: "SCoreDream";
}
.h3-st2 {
  text-align: center;
  display: flex;
  width: max-content;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  font-size: 40px;
  font-family: "SCoreDream";
  font-weight: 600;
  margin: 0 auto;
}
.h3-st2::before {
  content: "";
  display: flex;
  width: 40px;
  height: 40px;
  background-image: url(./images/point.png);
  background-size: cover;
}
.Pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
}
.Pagination li {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #aaa;
  color: #aaa;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}
.Pagination li:hover {
  background-color: #09abbe;
  color: #fff;
  border: unset;
}
.Pagination li:hover i {
  color: #fff;
}
.Pagination li.now {
  background-color: #09abbe;
  color: #fff;
  border: unset;
  pointer-events: none;
}
.Pagination li.ev-no {
  pointer-events: none;
  opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .h3-st1 {
    font-size: 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    letter-spacing: -0.6px;
  }
  .h3-st1 span {
    font-size: 17px;
  }
  .h3-st1::before {
    width: 32px;
    height: 32px;
  }
  .h3-st2 {
    font-size: 24px;
    gap: 16px;
  }
  .h3-st2 span {
    font-size: 17px;
  }
  .h3-st2::before {
    width: 32px;
    height: 32px;
  }
}
/* 검색결과가 없습니다 */
.noData-wrap {
  width: 100%;
}
.noData-wrap .noData-img {
  max-width: 637px;
  margin: 0 auto;
}

/*==================================================================================
    AboutUs 
===================================================================================*/
.AboutUs .banner-img {
  background-image: url(./images/sub-top-01.jpg);
}
.AboutUs.introduce .sub-menu3 {
  display: unset;
}
/* introduce */
.target .section1 {
  padding-top: 72px;
}
.target .section1 .img-wrap img {
  transform: translateY(13px);
}
.target .section2 {
  padding: 92px 0 125px;
  background-color: #e8e9eb;
  background-image: url(./images/esg-bg.jpg);
  background-position: center;
  background-size: cover;
}
.target .section2 .img-wrap {
  width: 100%;
  max-width: 933px;
  margin: 0 auto;
}
/* outline */
.outline .section1 {
  padding: 200px 0 98px;
}
.outline .section1 .flex-wrap {
  display: flex;
  position: relative;
  justify-content: flex-end;
}
.outline .section1 .flex-wrap .img-wrap {
  width: 52%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.outline .section1 .con-box {
  width: 100%;
  max-width: 70%;
  padding: 42px 46px 60px;
  border-radius: 20px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: flex-end;
}
.outline .section1 .con-box > div {
  width: 100%;
  max-width: 649px;
}
.outline .section1 .con-box h3 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.8px;
  color: #09abbe;
  padding-bottom: 26px;
  border-bottom: 1px solid #707070;
  margin-bottom: 34px;
}
.outline .section1 .con-box .list-wrap {
  display: flex;
}
.outline .section1 .con-box .list-wrap ul {
  width: 50%;
  gap: 24px;
  display: flex;
  flex-direction: column;
}
.outline .section1 .con-box .list-wrap ul li {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 24px;
  gap: 24px;
}
.outline .section1 .con-box .list-wrap ul li::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #09abbe;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.outline .section1 .con-box .list-wrap ul li span {
  letter-spacing: 1.6px;
  font-weight: bold;
  color: #474747;
}
.outline .section1 .con-box .list-wrap ul li span.letter {
  letter-spacing: -0.4px;
}
.outline .section1 .con-box .list-wrap ul li span.letter.sm {
  font-size: 14px;
}
.outline .section1 .con-box .list-wrap ul.right-ul span {
  width: 101px;
}
.outline .section1 .con-box .list-wrap ul li p {
  color: #474747;
  letter-spacing: -0.4px;
}
.outline .section2 {
  padding: 80px 0 80px;
}
.outline .section2 h3 {
  margin-bottom: 56px;
}
.outline .section2 ul {
  display: flex;
  width: 100%;
}
.outline .section2 ul li {
  width: calc(100% / 3);
  text-align: center;
  padding: 24px 24px 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.outline .section2 ul li img {
  width: 100%;
  max-width: 148px;
  margin-bottom: 24px;
}
.outline .section2 ul li .txt-top {
  font-size: 18px;
  font-family: "SCoreDream";
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-bottom: 32px;
}
.outline .section2 ul li .txt-ct {
  color: #474747;
  letter-spacing: -0.4px;
  margin-bottom: 72px;
}

.outline .section2 ul li .txt-ctl {
  color: #474747;
  letter-spacing: -0.4px;
  margin-bottom: 12px;
}
.outline .section2 ul li .txt-ctf {
  color: #474747;
  letter-spacing: -0.4px;
  margin: 10px 0 20px 0;
}
.outline .section2 ul li .txt-cts {
  color: #474747;
  letter-spacing: -0.4px;
  margin: -20px 0 12px 0;
}
.outline .section2 ul li .txt-ctt {
  color: #474747;
  letter-spacing: -0.4px;
  margin: 0 0 70px 0;
}
.outline .section2 ul li .txt-ctf {
  color: #474747;
  letter-spacing: -0.4px;
  margin: 0 0 12px 0;
}

.buttonSection {
  display: flex;
}
.outline .section2 ul li button {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 48px;
  border-radius: 24px;
  background-color: #e4e4e4;
  font-weight: bold;
  transition: all 0.3s;
  margin-right: 8px;
}
.outline .section2 ul li button:hover {
  background-color: #09abbe;
  color: #fff;
}
.outline .section3 {
  padding-bottom: 80px;
}
.outline .section3 h3.h3-st1 {
  margin-bottom: 56px;
}
.outline .section3 .flax-wrap {
  display: flex;
}
.outline .section3 .flax-wrap .flax-box {
  width: 50%;
}
.outline .section3 .flax-wrap img {
  width: 100%;
  height: 257px;
  margin-bottom: 58px;
}
.outline .section3 .flax-wrap h4 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.8px;
  color: #09abbe;
  margin-bottom: 24px;
}
.outline .section3 .flax-wrap h4 span {
  font-size: 24px;
  letter-spacing: -0.6px;
  color: #b2b2b2;
  margin-left: 16px;
}
.outline .section3 .flax-wrap ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.outline .section3 .flax-wrap ul li {
  font-size: 17px;
  letter-spacing: -0.43px;
  color: #474747;
  padding-left: 32px;
  position: relative;
}
.outline .section3 .flax-wrap ul li::before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #474747;
  position: absolute;
  border-radius: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.outline .section4 {
  background-color: #e8e9eb;
}
.outline .section4 .container {
  width: 100%;
  max-width: 1920px;
}

.outline .section5 {
  padding: 80px 0 120px;
}
.outline .section5 .img-wrap {
  max-width: 1196px;
  margin: 0 auto;
}
/* management */

/* ~ 20240318 Start */
.management .content_container{
  max-width: 1440px;
}
.management .section1 .ceo_banner_wrap{
  position: relative;
  border-radius: 16px;
  padding: 0px 80px 56px 80px;
  background-color: #f5f5f5;
}
.management .ceo_banner_wrap .banner_txt_wrap{
  overflow: hidden;
}
.management .ceo_banner_wrap .banner_txt,
.management .ceo_banner_wrap .banner_tit,
.management .ceo_banner_wrap .banner_tit>.point{
  font-family: "SCoreDream", sans-serif;
}
.management .ceo_banner_wrap .banner_txt{
  line-height: 0.9em;
  font-weight: 900;
  font-size: 100px;
  -webkit-text-stroke: 1px #fff;
  margin-top: -15px;
  color: transparent;
}
.management .ceo_banner_wrap .banner_tit,
.management .ceo_banner_wrap .banner_tit>.point{
  letter-spacing: -1px;
  line-height: 64px;
  font-size: 40px;
  font-weight: 600;
}
.management.en .ceo_banner_wrap .banner_tit,
.management.en .ceo_banner_wrap .banner_tit>.point{
  font-size: 34px;
}
.management .ceo_banner_wrap .banner_tit{
  margin-bottom: 40px;
}
.management .ceo_banner_wrap .banner_tit>.point_1{
  color: #09abbe;
}
.management .ceo_banner_wrap .banner_tit>.point_2{
  color: #3963b5;
}
.management .ceo_banner_wrap .banner_img{
  width: auto;
  height: 46px;
}
.management .ceo_banner_wrap .ceo_photo{
  position: absolute;
  bottom: 0px;
  right: 80px;
  width: 510px;
}
.management .main_txt_wrap{
  padding: 40px 80px;
}
.management .main_txt_wrap .main_tit{
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 32px;
}
.management .main_txt_wrap .main_txt{
  letter-spacing: -0.43px;
  line-height: 28px;
  font-size: 17px;
  margin-bottom: 38px;
}
.main_txt_bottom{
  margin-top: 56px;
}
.main_txt_bottom>strong{
  font-weight: bold;
}
@media all and (max-width:1520px){
  .management .section1 .ceo_banner_wrap{
    padding-bottom: 0px;
  }
  .management .ceo_banner_wrap *{
    text-align: center;
  }
  .management .ceo_banner_wrap img{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .management .ceo_banner_wrap .ceo_photo{
    position: static;
    margin-top: 32px;
  }
}
@media all and (max-width:768px){
  .management .section1 .ceo_banner_wrap{
    padding: 0px 18px;
  }
  .management .ceo_banner_wrap .banner_txt{
    font-size: 40px;
    margin-top: -5px;
  }
  .management .ceo_banner_wrap .banner_tit,
  .management .ceo_banner_wrap .banner_tit>.point,
  .management.en .ceo_banner_wrap .banner_tit,
  .management.en .ceo_banner_wrap .banner_tit>.point{
    line-height: 40px;
    font-size: 24px;
  }
  .management .ceo_banner_wrap .banner_img{
    height: 88px;
  }
  .management .ceo_banner_wrap .ceo_photo{
    width: auto;
    height: 237px;
  }
  .management .main_txt_wrap{
    padding: 0px;
    margin-top: 38px;
  }
  .management .main_txt_wrap .main_tit{
    line-height: 30px;
    font-size: 18px;
  }
  .management .main_txt_wrap .main_txt{
    line-height: 21px;
    font-size: 14px;
    margin-bottom: 21px;
  }
  .management .main_txt_wrap .main_txt_bottom{
    margin-top: 21px;
  }
}
@media all and (max-width:375px){
  .management .ceo_banner_wrap .banner_img{
    width: 100%;
    height: auto;
  }
}
/* ~ 20240318 End */
.management .section1 {
  padding: 80px 0 120px;
}
.management .section1 h3 {
  margin-bottom: 56px;
}
.management .section1 .boss-txt {
  max-width: 32px;
  margin: 0 auto;
}
.management .section1 .boss-wrap {
  position: relative;
  z-index: 2;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 56px;
}
.management .section1 .boss-wrap::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 2px;
  height: 79%;
  background-color: #09abbe;
  z-index: -1;
}
.management .section1 .boss-wrap .boss-le {
  display: flex;
  justify-content: flex-start;
}
.management .section1 .boss-wrap .boss-le img {
  width: calc(55%);
}
.management .section1 .boss-wrap .boss-ri {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  top: 52%;
  transform: translateY(-50%);
}
.management .section1 .boss-wrap .boss-ri img {
  width: calc(55%);
}
.management .section1 .boss2-wrap {
  position: relative;
  z-index: 1;
  display: flex;
  padding-bottom: 300px;
}
.management .section1 .boss2-wrap::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 32%;
  transform: translateX(-50%);
  width: 2px;
  height: calc(100%);
  background-color: #1673b9;
  z-index: -1;
}
.management .section1 .boss2-wrap .boss-le {
  width: 51.26%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.management .section1 .boss2-wrap .boss-img {
  width: 83%;
}
.management .section1 .boss2-wrap .boss-ri {
  width: 51.26%;
  padding-top: 271px;
  position: absolute;
  right: 0;
}
.management .section1 .boss2-wrap .box {
  padding-bottom: 200px;
}
.management .section1 .box {
  opacity: 0;
  transition: all 0.3s;
  padding-bottom: 150px;
}
.management .section1 .box.active {
  opacity: 1;
}
.management .section1 .box img {
  transform: translateY(30px);
  transition: all 0.5s;
}
.management .section1 .box.active img {
  transform: translateY(0px);
}

/* cooperation */
.cooperation .section1 {
  padding: 80px 0 120px;
}
/* history */
.history .section1 {
  padding: 80px 0 120px;
}
.history .section1 .h3-st2 {
  margin-bottom: 80px;
}
.history .history-wrap {
  border-top: 4px solid #09abbe;
  position: relative;
}
.history .time-wrap {
  position: relative;
}
.history .time-wrap .time {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 80px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 4.8px;
  color: #d0d5de;
  opacity: 0.2;
}
.history .year-wrap {
  display: flex;
  border-bottom: 4px dashed #e4e4e4;
  position: relative;
  z-index: 1;
}
.history .year-wrap .year {
  width: 256px;
  padding-left: 16px;
  padding-top: 56px;
  font-size: 80px;
  font-weight: bold;
  line-height: 0.88;
  letter-spacing: -2px;
  color: #09abbe;
  position: relative;
}
.history .year-wrap .year::before {
  width: 2px;
  height: calc(100% + 4px);
  background-color: #09abbe;
  position: absolute;
  right: -1px;
  bottom: -4px;
  content: "";
}
.history .month-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: calc(100% - 256px);
  padding: 80px 16px 40px 0;
}
.history .month-wrap li {
  display: flex;
  padding-left: 56px;
  position: relative;
}
.history .month-wrap li::before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #09abbe;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.history .month-wrap li:first-child::before {
  width: 16px;
  height: 16px;
  z-index: 1;
}
.history .month-wrap li:first-child::after {
  content: "";
  width: 32px;
  height: 32px;
  border: solid 2px #09abbe;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.history .month-wrap li span {
  line-height: 1.5;
  width: 40px;
  font-weight: 500;
  letter-spacing: -0.4px;
}
.history .month-wrap li p {
  line-height: 1.5;
  width: calc(100% - 40px);
  font-weight: 300;
  letter-spacing: -0.4px;
}
.history .time-wrap:first-child .year-wrap .year {
  padding-top: 24px;
}
.history .time-wrap:first-child .year-wrap .year::before {
  height: calc(100% + 4px - 72px);
}
.history .time-wrap:first-child .year-wrap .year::after {
  position: absolute;
  width: 1px;
  height: 32px;
  border-left: 2px dashed #09abbe;
  right: -2px;
  top: 12px;
  content: "";
}
.history .time-wrap:first-child .month-wrap {
  padding-top: 48px;
}
.history .time-wrap:nth-child(2n - 1)::before {
  width: 100vw;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fbfbfb;
  content: "";
}

.history .control-wrap {
  position: absolute;
  left: calc(100% + 16px);
  height: 100%;
  z-index: 1;
}
.history .control-wrap .control-box {
  position: sticky;
  top: 200px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.history .control-wrap .control-top {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 56px;
  border-radius: 16px 16px 0 0;
  background-color: #09abbe;
  transition: all 0.3s;
}
.history .control-wrap .control-top span {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #fff;
}
.history .control-wrap .control-time > span {
  width: 100%;
  display: flex;
  padding: 8px 0 8px 16px;
  align-items: center;
  background-color: #eaf1f2;
  font-weight: 500;
  letter-spacing: -0.4px;
}
.history .control-wrap .control-year li {
  font-size: 15px;
  letter-spacing: -0.38px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  cursor: pointer;
  background-color: #fff;
}
.history .control-wrap .control-year li:hover {
  color: #09abbe;
}
.history .control-wrap .control-year li.active {
  color: #09abbe;
}

/* Finance */
.finance .section1 {
  padding-top: 80px;
  margin-bottom: 80px;
}
.finance .h3-st1 {
  margin-bottom: 65px;
  text-align: left;
}
.finance .h3-st1 span {
  font-size: 24px;
}
.finance .flex-wrap {
  display: flex;
  gap: 3%;
}
.finance .table-wrap table {
  width: 100%;
  text-align: center;
}
.finance .table-wrap table tr {
  height: 64px;
}
.finance .table-wrap table tr.bd-color {
  border-bottom: 2px solid #09abbe;
}
.finance .table-wrap table tr th {
  width: calc(100% / 5);
  border: solid 1px #f5f5f5;
  background-color: #09abbe;
  color: #fff;
  letter-spacing: -0.43px;
  font-size: 17px;
  font-weight: bold;
}
.finance .table-wrap table tr td {
  padding: 0 40px 0 16px;
  text-align: right;
  border: solid 1px #e4e4e4;
  font-size: 17px;
  letter-spacing: -0.43px;
}
.finance .table-wrap table tr td:first-child {
  text-align: center;
  padding: 0 16px;
  background-color: #f5f5f5;
  font-weight: bold;
}
.finance .table-wrap table tr td:last-child {
  background-color: #cff6fb;
}
.finance .table-wrap table tr td.color {
  color: #008191;
}
.finance .section1 .graph-wrap {
  width: 36%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.finance .section1 .graph-wrap .graph-box {
  height: 100%;
  width: 100%;
}
.finance .section1 .table-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  min-width: unset;
}
.finance .section2 {
  margin-bottom: 120px;
}
.finance .section2 .flex-wrap {
  margin-bottom: 56px;
}
.finance .section2 .graph-wrap {
  width: calc(100% - 40px - 384px);
}
.finance .section2 .graph-wrap .graph-box {
  width: 100%;
  height: 100%;
}
.finance .recharts-layer > g:nth-child(1) path {
  fill: #008191;
}
.finance .recharts-layer > g:nth-child(2) path {
  fill: #09abbe;
}
.finance .recharts-layer > g:nth-child(3) path {
  fill: #cff6fb;
}

.finance .section2 .img-wrap {
  width: 100%;
  display: flex;
}

#img1 {
  margin-right: 40px;
}
#img2 {
  margin-right: 40px;
}

.finance .section2 .table-wrap {
  min-width: unset;
}

@media screen and (max-width: 1280px) {
  .history .control-wrap {
    display: none;
  }
}

@media screen and (max-width: 1180px) {
  .outline .section1 {
    padding: 40px 0 0;
  }
  .outline .section1 .flex-wrap {
    justify-content: unset;
    flex-direction: column;
    align-items: center;
  }
  .outline .section1 .flex-wrap .img-wrap {
    position: static;
    transform: translateY(7%);
  }
  .outline .section1 .flex-wrap .img-wrap .pc {
    display: none;
  }
  .outline .section1 .flex-wrap .img-wrap .mb {
    display: unset;
  }
  .outline .section1 .con-box {
    max-width: unset;
    justify-content: unset;
  }
  .outline .section1 .con-box > div {
    max-width: unset;
  }
  .outline .section2 {
    padding: 40px 0 40px;
  }
  .outline .section2 h3 {
    margin-bottom: 32px;
  }
  .outline .section2 ul {
    flex-wrap: wrap;
    gap: 40px 0;
  }
  .outline .section2 ul li {
    width: calc(100% / 2);
    padding: 0 16px;
  }
  .outline .section2 ul li .txt-top {
    font-size: 16px;
    letter-spacing: -0.4px;
    margin-bottom: 24px;
  }
  .outline .section2 ul li .txt-ct {
    font-size: 14px;
    margin-bottom: 32px;
  }
  .outline .section2 ul li button {
    background-color: #09abbe;
    color: #fff;
  }

  .history .section1 {
    padding: 40px 0 60px;
  }
  .history .section1 .h3-st2 {
    margin-bottom: 40px;
  }
  .history .history-wrap {
    border-top: 2px solid #09abbe;
  }
  .history .time-wrap .year-wrap .year {
    width: 64px;
    padding-left: 0;
    padding-top: 32px;
    font-size: 18px;
    letter-spacing: -0.45px;
  }
  .history .time-wrap .month-wrap {
    width: calc(100% - 64px);
    padding: 32px 16px 32px 0;
    gap: 16px;
  }
  .history .time-wrap:first-child .month-wrap {
    padding-top: 32px;
  }
  .history .time-wrap:first-child .year-wrap .year {
    padding-top: 32px;
  }
  .history .time-wrap:first-child .year-wrap .year::before {
    height: calc(100% + 4px);
  }
  .history .month-wrap li {
    padding-left: 24px;
  }
  .history .month-wrap li::before {
    width: 8px;
    height: 8px;
  }
  .history .month-wrap li:first-child::before {
    width: 12px;
    height: 12px;
  }
  .history .month-wrap li:first-child::after {
    width: 20px;
    height: 20px;
  }
  .history .month-wrap li span {
    width: 27px;
    font-size: 14px;
  }
  .history .month-wrap li p {
    font-size: 12px;
    line-height: 1.6;
  }
  .history .time-wrap .time {
    display: none;
  }
  .history .year-wrap {
    border-bottom: 1px solid #09abbe;
  }
}

@media screen and (max-width: 768px) {
  .target .section1 {
    padding: 40px 0;
  }
  .target .section1 .img-wrap {
    width: 100%;
    max-width: 368px;
    margin: 0 auto;
  }
  .target .section2 {
    padding: 80px 0 140px;
  }
  .target .section2 .img-wrap {
    width: 100%;
    max-width: 368px;
    margin: 0 auto;
  }
  .target .section1 .img-wrap img {
    transform: unset;
  }
  .outline .section3 {
    padding-bottom: 40px;
  }
  .outline .section3 h3.h3-st1 {
    margin-bottom: 40px;
  }
  .outline .section3 .flax-wrap img {
    height: 135px;
    margin-bottom: 24px;
  }
  .outline .section3 .flax-wrap h4 {
    font-size: 24px;
    letter-spacing: -0.6px;
  }
  .outline .section3 .flax-wrap h4 span {
    font-size: 17px;
  }
  .outline .section3 .flax-wrap ul li {
    font-size: 14px;
    padding-left: 14px;
  }
  .outline .section3 .flax-wrap ul li::before {
    width: 6px;
    height: 6px;
  }
  .outline .section4 .img-wrap {
    max-width: 368px;
    margin: 0 auto;
  }
  .outline .section5 .img-wrap {
    max-width: 368px;
  }
  .management .section1 {
    padding: 40px 0 60px;
  }
  .management .section1 .mb .img-wrap {
    max-width: 368px;
    margin: 0 auto;
  }
  .cooperation .section1 {
    padding: 40px 0 60px;
  }
  .cooperation .section1 .img-wrap {
    width: 100%;
    max-width: 368px;
    margin: 0 auto;
  }
  .finance .section1 {
    padding-top: 40px;
    margin-bottom: 40px;
  }
  .finance .h3-st1 {
    margin-bottom: 32px;
  }
  .finance .flex-wrap {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  .finance .section1 .graph-wrap {
    width: 100%;
    max-width: 375px;
    height: 376px;
  }
  .finance .section1 .table-wrap {
    width: 100%;
  }
  .finance .table-wrap table tr {
    height: 48px;
  }
  .finance .table-wrap table tr th {
    font-size: 14px;
  }
  .finance .table-wrap table tr td {
    font-size: 14px;
    padding: 0 8px;
  }
  .finance .table-wrap table tr td:first-child {
    padding: 0 8px;
  }
  .finance .section2 .graph-wrap {
    height: 376px;
    width: 100%;
    max-width: 375px;
  }
  .finance .section2 .img-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 384px;
  }
  #img1 {
    margin-bottom: 10px;
  }
  #img2 {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 680px) {
  .outline .section1 .flex-wrap {
    margin-top: -54px;
  }
  .outline .section1 .con-box {
    padding: 86px 24px 24px;
  }
  .outline .section1 .con-box h3 {
    font-size: 20px;
    text-align: center;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
  .outline .section1 .con-box .list-wrap ul li span {
    font-size: 14px;
  }
  .outline .section1 .con-box .list-wrap ul li {
    font-size: 14px;
  }
  .outline .section1 .flex-wrap .img-wrap {
    width: 100%;
    max-width: 297px;
    transform: translateY(18%);
  }
}

@media screen and (max-width: 580px) {
  .outline .section1 .con-box .list-wrap {
    flex-direction: column;
    gap: 16px;
  }
  .outline .section1 .con-box .list-wrap ul {
    gap: 16px;
    width: 100%;
  }
  .outline .section1 .con-box .list-wrap ul li span {
    width: 89px;
  }
  .outline .section1 .con-box .list-wrap ul.right-ul span {
    width: 89px;
  }
  .outline .section2 ul {
    flex-direction: column;
  }
  .outline .section2 ul li {
    width: 100%;
  }
  .outline .section3 .flax-wrap {
    flex-direction: column;
    gap: 40px;
  }
  .outline .section3 .flax-wrap .flax-box {
    width: 100%;
  }
}

/* ==================================================================================
    R&D
=====================================================================================*/
.research .banner-img {
  background-image: url(./images/sub-top-02.jpg);
}
/* =========== sub-menu ====================*/
.research.ovc .sub-menu3 {
  display: unset;
}
.research.result .sub-menu4 {
  display: unset;
}

/* 자세히 보기 */
.research .example-box {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}
.research .example-box .txt-top {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #232323;
  margin-bottom: 16px;
  line-height: 1;
}
.research .example-box .txt-bt {
  font-weight: 300;
  letter-spacing: -0.4px;
  color: #232323;
}
.research .example-box .example-wrap {
  width: 240px;
  height: 64px;
  background-color: #09abbe;
  border-radius: 32px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .research .example-box {
    flex-direction: column;
    gap: 32px;
  }
  .research .example-box .txt-top {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.4px;
  }
  .research .example-box .txt-bt {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    letter-spacing: -0.35px;
  }
  .research .example-box .example-wrap {
    width: 160px;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    letter-spacing: -0.35px;
  }
}

/* OVC 소개영상 */
.ovc-picture .section1 {
  padding: 80px 0 120px;
}
.ovc-picture .section1 .flex-wrap {
  display: flex;
  gap: 40px;
  position: relative;
  flex-direction: column;
  align-items: center;
}
.ovc-picture .section1 .player-wrapper {
  width: 100%;
  max-width: 1200px;
  height: auto;
  z-index: 1;
  position: relative;
}
.ovc-picture .section1 .player-wrapper::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 56%;
}
.ovc-picture .section1 .player-wrapper .react-player {
  position: absolute;
  left: 0;
  top: 0;
}
.ovc-picture .section1 .txt-wrap {
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.ovc-picture .section1 .txt-wrap .txt-top p {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #aaa;
}
.ovc-picture .section1 .txt-wrap .txt-ct p {
  font-size: 32px;
  letter-spacing: -0.8px;
  color: #232323;
  font-weight: 500;
}

/* 핵심소재 */
.material .section1 {
  padding-top: 80px;
  margin-bottom: 80px;
}
.material .section1 .flex-wrap {
  display: flex;
  gap: 80px;
}
.material .section1 .flex-wrap .fl-le {
  width: 50%;
  position: relative;
}
.material .section1 .flex-wrap .fl-le img {
  width: 129%;
  position: absolute;
  right: 0;
  top: 40px;
}
.material .section1 .flex-wrap .fl-ri {
  width: 482px;
}

.material .section2 {
  margin-bottom: 80px;
}
.material .section2 .h3-st1 {
  margin-bottom: 40px;
}
.material .section3 {
  margin-bottom: 120px;
}
.material .section3 .h3-st1 {
  margin-bottom: 56px;
}
.material .section3 .flex-wrap {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}
.material .section3 .flex-wrap li {
  width: calc((100% - 40px) / 2);
  min-height: 275px;
  padding: 72px 48px 48px;
  border-radius: 16px;
  background-color: #f5f5f5;
}
.material .section3 .flex-wrap li h4 {
  font-family: "SCoreDream";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
  color: #09abbe;
  position: relative;
  margin-bottom: 24px;
  z-index: 1;
}
.material .section3 .flex-wrap li h4 span {
  font-family: "SCoreDream";
  font-weight: 500;
  position: absolute;
  bottom: -2px;
  left: -24px;
  font-size: 60px;
  letter-spacing: -1.5px;
  opacity: 0.3;
  color: #d0d5de;
  z-index: -1;
}
.material .section3 .flex-wrap li p {
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #232323;
}
.material .section3 .flex-wrap li p span {
  font-weight: bold;
}
/* 인체임상시험 */
.exam .section1 {
  padding-top: 80px;
  margin-bottom: 80px;
}
.exam .section1 h3 {
  margin-bottom: 40px;
}
.exam .section1 .table-wrap {
  width: 100%;
}
.exam .section1 .table-wrap table {
  width: 100%;
}
.exam .section1 .list-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f5f5f5;
  padding: 40px;
}
.exam .section1 .list-wrap li {
  position: relative;
  line-height: 1.71;
  letter-spacing: -0.35px;
  padding-left: 16px;
  font-size: 14px;
}
.exam .section1 .list-wrap li::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  left: 0;
  top: 7px;
  background-color: #09abbe;
  border-radius: 100%;
}
.exam .section2 {
  margin-bottom: 120px;
}
.exam .section2 .h3-st1 {
  margin-bottom: 40px;
  align-items: flex-end;
}
.exam .section2 .h3-st1 span {
  font-family: "SCoreDream";
  font-size: 24px;
  font-weight: 600;
  color: #232323;
}
/* 확장성 */
.Expandability .bt-le {
  display: flex;
  justify-content: flex-end;
}
.Expandability .bt-le p {
  font-size: 14px;
  letter-spacing: -0.35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 40px;
  border-radius: 24px;
  background-color: #f5f5f5;
  text-align: right;
}
.Expandability .section1 {
  padding-top: 80px;
  margin-bottom: 80px;
}
.Expandability .section1 h3 {
  margin-bottom: 48px;
  text-align: left;
  align-items: center;
}
.Expandability .section1 .flex-wrap {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.Expandability .section1 .flex-wrap li {
  width: calc((100% - 40px) / 2);
  min-height: 275px;
  padding: 72px 48px 48px;
  border-radius: 16px;
  background-color: #f5f5f5;
}
.Expandability .section1 .flex-wrap li h4 {
  font-family: "SCoreDream";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
  color: #09abbe;
  position: relative;
  margin-bottom: 24px;
  z-index: 1;
}
.Expandability .section1 .flex-wrap li h4 span {
  font-family: "SCoreDream";
  font-weight: 500;
  position: absolute;
  bottom: -2px;
  left: -24px;
  font-size: 60px;
  letter-spacing: -1.5px;
  opacity: 0.3;
  color: #d0d5de;
  z-index: -1;
}
.Expandability .section1 .flex-wrap li p {
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #232323;
}
.Expandability .section1 .flex-wrap li p.txt-ct {
  font-size: 18px;
  letter-spacing: -0.45px;
  margin-bottom: 16px;
}
.Expandability .section2 {
  margin-bottom: 120px;
}
.Expandability .section2 h3 {
  margin-bottom: 48px;
}
.Expandability .section2 .list-wrap {
  display: flex;
  flex-direction: column;
  border-top: 2px solid #747474;
  margin-bottom: 40px;
}
.Expandability .section2 .list-wrap li {
  padding: 24px 0;
  display: flex;
  border-bottom: 1px solid #aaaaaa;
  align-items: center;
}
.Expandability .section2 .list-wrap li .img-wrap {
  width: 148px;
}
.Expandability .section2 .list-wrap li .txt-wrap {
  width: calc(100% - 148px);
  padding: 0 40px;
}
.Expandability .section2 .list-wrap li .txt-wrap h4 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  font-family: "SCoreDream";
  margin-bottom: 24px;
}
.Expandability .section2 .list-wrap li .txt-wrap p {
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #474747;
}

/* 특허목록 */
.Patent .sub-con {
  padding-top: 80px;
  margin-bottom: 120px;
}
.Patent .tab-btn {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 32px;
  height: 64px;
  margin-bottom: 72px;
}
.Patent .tab-btn li {
  text-align: center;
  width: calc(100% / 5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #232323;
  letter-spacing: -0.45px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 32px;
  cursor: pointer;
  transition: all 0.3s;
}
.Patent .tab-btn li.now {
  background-color: #09abbe;
  color: #fff;
}
.Patent .tab-btn li:hover {
  background-color: #09abbe;
  color: #fff;
}

.Patent .h3-st1 {
  font-size: 32px;
  font-weight: 400;
  align-items: center;
  margin-bottom: 48px;
}
.Patent .Patent-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.Patent .Patent-list li {
  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  border: solid 0.5px #aaa;
  border-radius: 16px;
}
.Patent .Patent-list li .title-wrap {
  display: flex;
  padding: 24px;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.Patent .Patent-list li .title-wrap .img-wrap {
  width: 92px;
  height: 92px;
  background-image: url(./images/Patent-icon.png);
  background-position: center;
  background-size: cover;
}
.Patent .Patent-list li .title-wrap .txt-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: calc(100% - 92px);
  padding-left: 24px;
  padding-right: 72px;
}
.Patent .Patent-list li .title-wrap .txt-wrap .num {
  font-size: 18px;
  font-weight: 400;
  color: #aaa;
  font-family: "SCoreDream";
}
.Patent .Patent-list li .title-wrap .txt-wrap .title {
  font-size: 20px;
  font-weight: 500;
  color: #232323;
}
.Patent .Patent-list li .title-wrap > i {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 32px;
  color: #787878;
}
.Patent .Patent-list li .Patent-con {
  display: none;
  gap: 24px;
  padding: 24px 24px 24px 140px;
  position: relative;
}
.Patent .Patent-list li .Patent-con.open {
  display: flex;
}
.Patent .Patent-list li .Patent-con::before {
  position: absolute;
  content: "";
  width: calc(100% - 40px);
  height: 0.5px;
  background-color: #787878;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.Patent .Patent-list li .Patent-con p {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.45px;
  color: #232323;
}
.Patent .Patent-list li .Patent-con p span {
  font-weight: 300;
}
.Patent .btn-st1 {
  margin-top: 80px;
}
/* .Patent .Patent-list li .toggle-wrap { height: 0; overflow: hidden; } */
.Patent .scroll-wrap {
  width: 100%;
}
.Patent .table-wrap {
  width: 100%;
}
.Patent .table-wrap table {
  width: 100%;
  text-align: center;
}
.Patent .table-wrap table th {
  background-color: #09abbe;
  border: solid 1px #d6d6d6;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.Patent .table-wrap table th:last-child {
  width: 45%;
}
.Patent .table-wrap table tr {
  height: 64px;
}
.Patent .table-wrap table td {
  border: solid 1px #d6d6d6;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: -0.35px;
  padding: 0 16px;
}
.Patent .table-wrap table td:first-child {
  background-color: #eaf1f2;
  color: #09abbe;
}
.Patent .table-wrap table td.sm-ft {
  font-size: 14px;
}
/* 바이러스 관련 지적재산권 */
.Virus .sub-con {
  padding-top: 80px;
  margin-bottom: 120px;
}
.Virus .h3-st1 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 32px;
}
.Virus .scroll-wrap {
  width: 100%;
}
.Virus .table-wrap {
  width: 100%;
}
.Virus .table-wrap table {
  width: 100%;
  text-align: center;
}
.Virus .table-wrap table th {
  background-color: #09abbe;
  border: solid 1px #d6d6d6;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.Virus .table-wrap table tr {
  height: 64px;
}
.Virus .table-wrap table td {
  border: solid 1px #d6d6d6;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: -0.4px;
  padding: 8px 16px;
}
.Virus .table-wrap table td:first-child {
  background-color: #f5f5f5;
}
.Virus .table-wrap table td.sm-ft {
  font-size: 14px;
}

@media screen and (max-width: 960px) {
  .Patent .tab-btn {
    flex-wrap: wrap;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 40px;
  }
  .Patent .tab-btn li {
    line-height: 1.42;
    padding: 0 8px;
    width: calc(100% / 3);
    height: 48px;
    border-radius: unset;
    font-size: 12px;
    word-break: keep-all;
  }
}

@media screen and (max-width: 768px) {
  .ovc-picture .section1 {
    padding: 40px 0 64px;
  }
  .ovc-picture .section1 .flex-wrap {
    gap: 24px;
  }
  .ovc-picture .section1 .txt-wrap {
    gap: 16px;
  }
  .ovc-picture .section1 .txt-wrap .txt-top p {
    font-size: 14px;
  }
  .ovc-picture .section1 .txt-wrap .txt-ct p {
    font-size: 20px;
  }

  .exam .section1 {
    padding-top: 40px;
    margin-bottom: 40px;
  }
  .exam .section1 .list-wrap {
    padding: 32px 24px;
  }
  .exam .section1 .list-wrap li {
    font-size: 14px;
  }
  .exam .section2 {
    margin-bottom: 60px;
  }
  .exam .section2 .h3-st1 {
    align-items: center;
  }
  .exam .section2 .h3-st1 span {
    font-size: 17px;
  }
  .exam .section2 .img-wrap {
    width: 100%;
    max-width: 368px;
    margin: 0 auto;
  }

  .material .section1 {
    padding-top: 40px;
    margin-bottom: 40px;
  }
  .material .section1 .img-wrap {
    width: 100%;
    max-width: 368px;
    margin: 0 auto;
  }
  .material .section2 .img-wrap {
    width: 100%;
    max-width: 368px;
    margin: 0 auto;
  }
  .material .section3 .flex-wrap {
    gap: 24px;
  }
  .material .section3 .flex-wrap li {
    width: 100%;
    padding: 46px 24px 24px 46px;
    min-height: unset;
  }
  .material .section3 .flex-wrap li h4 span {
    font-size: 38px;
    bottom: 5px;
  }
  .material .section3 .flex-wrap li h4 {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .material .section3 .flex-wrap li p.txt-ct {
    font-size: 16px;
  }
  .material .section3 .flex-wrap li p {
    font-size: 13px;
  }
  .material .section3 {
    margin-bottom: 60px;
  }
  .material .section3 .list-wrap li {
    padding: 40px 0;
    flex-direction: column;
    gap: 24px;
  }
  .material .section3 .list-wrap li .txt-wrap {
    text-align: center;
    width: 100%;
    padding: unset;
  }
  .material .section3 .list-wrap li .txt-wrap h4 {
    margin-bottom: 16px;
    font-size: 16px;
  }
  .material .section3 .list-wrap li .txt-wrap p {
    font-size: 14px;
    letter-spacing: -0.35px;
  }

  .Expandability .section1 {
    padding-top: 40px;
    margin-bottom: 40px;
  }
  .Expandability .section1 .flex-wrap {
    gap: 24px;
  }
  .Expandability .section1 .flex-wrap li {
    width: 100%;
    padding: 46px 24px 24px 46px;
    min-height: unset;
  }
  .Expandability .section1 .flex-wrap li h4 span {
    font-size: 38px;
    bottom: 5px;
  }
  .Expandability .section1 .flex-wrap li h4 {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .Expandability .section1 .flex-wrap li p.txt-ct {
    font-size: 16px;
  }
  .Expandability .section1 .flex-wrap li p {
    font-size: 13px;
  }
  .Expandability .bt-le p {
    padding: 12px 24px;
  }
  .Expandability .section2 {
    margin-bottom: 60px;
  }
  .Expandability .section2 .list-wrap li {
    padding: 40px 0;
    flex-direction: column;
    gap: 24px;
  }
  .Expandability .section2 .list-wrap li .txt-wrap {
    text-align: center;
    width: 100%;
    padding: unset;
  }
  .Expandability .section2 .list-wrap li .txt-wrap h4 {
    margin-bottom: 16px;
    font-size: 16px;
  }
  .Expandability .section2 .list-wrap li .txt-wrap p {
    font-size: 14px;
    letter-spacing: -0.35px;
  }

  .Patent .sub-con {
    padding-top: 40px;
    margin-bottom: 60px;
  }
  .Patent .h3-st1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 32px;
  }
  .Patent .Patent-list li .title-wrap {
    padding: 24px 24px 16px;
    flex-direction: column;
    align-items: unset;
  }
  .Patent .Patent-list li .title-wrap .txt-wrap {
    padding: unset;
    width: calc(100% - 24px);
  }
  .Patent .Patent-list li .title-wrap .img-wrap {
    margin-bottom: 24px;
    width: 72px;
    height: 72px;
  }
  .Patent .Patent-list li .title-wrap .txt-wrap .num {
    font-size: 14px;
  }
  .Patent .Patent-list li .title-wrap .txt-wrap .title {
    font-size: 16px;
  }
  .Patent .Patent-list li .title-wrap > i {
    right: 24px;
    bottom: 16px;
    top: unset;
    transform: unset;
    font-size: 20px;
  }
  .Patent .Patent-list li .Patent-con {
    padding: 16px 24px 24px;
    flex-direction: column;
    gap: 8px;
  }
  .Patent .Patent-list li .Patent-con p {
    font-size: 16px;
  }
  .Patent .table-wrap {
    max-width: 760px;
  }
  .Patent .table-wrap table th {
    font-size: 16px;
  }
  .Patent .table-wrap table th:first-child {
    width: 141px;
  }
  .Patent .table-wrap table th:nth-child(2) {
    width: 128px;
  }
  .Patent .btn-st1 {
    margin-top: 40px;
  }

  .Virus .sub-con {
    padding-top: 40px;
    margin-bottom: 60px;
  }
  .Virus .h3-st1 {
    word-break: keep-all;
    font-weight: bold;
    font-size: 24px;
    line-height: normal;
    padding: 0 40px;
  }
}
/* ==================================================================================
    Product
=====================================================================================*/
.Product .banner-img {
  background-image: url(./images/sub-top-03.jpg);
}
/* Clean Tech */
.CleanTech .sub-con {
  padding-top: 80px;
  margin-bottom: 120px;
}
.CleanTech .search-wrap {
  width: 100%;
  max-width: 738px;
  height: 88px;
  margin: 0 auto 56px;
  display: flex;
  border-radius: 44px;
  box-shadow: inset 5px 5px 5px 0 rgba(208, 213, 222, 0.51);
  border: solid 1px #d0d5de;
  background-color: #f5f5f5;
  padding-left: 51px;
}
.CleanTech .search-wrap input {
  width: calc(100% - 88px);
  border: unset;
  background-color: transparent;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.45px;
}
.CleanTech .search-wrap button {
  width: 88px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #09abbe;
}
.CleanTech .search-wrap button i {
  font-size: 32px;
  color: #fff;
}
.CleanTech .tab-btn {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-bottom: 56px;
}
.CleanTech .tab-btn li {
  width: calc(100% / 5);
  max-width: 160px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: solid 2px #c1ccce;
  background-color: #fff;
  font-size: 17px;
  font-weight: 500;
  color: #c1ccce;
  font-family: "SCoreDream";
  cursor: pointer;
  transition: all 0.3s;
}
.CleanTech .tab-btn li.now {
  background-color: #09abbe;
  color: #fff;
  border: solid 2px #09abbe;
}
.CleanTech .tab-btn li:hover {
  background-color: #09abbe;
  color: #fff;
  border: solid 2px #09abbe;
}
.CleanTech .product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 80px;
}
.CleanTech .product-list li {
  width: calc((100% - 80px) / 3);
  border-radius: 16px;
  transition: all 0.3s;
}
.CleanTech .product-list li:hover {
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.13);
}
.CleanTech .product-list li .img-wrap {
  width: 100%;
  padding-top: 68%;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  background-color: #09abbe;
}
.CleanTech .product-list li .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}
.CleanTech .product-list li .title-wrap {
  padding: 24px 24px 40px;
}
.CleanTech .product-list li .classify {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;
}
.CleanTech .product-list li .classify span {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #d0d5de;
}
.CleanTech .product-list li .classify span i {
  color: #fff;
}
.CleanTech .product-list li .classify p {
  color: #d0d5de;
  width: calc(100% - 32px - 8px);
}
.CleanTech .product-list li .title {
  font-size: 20px;
  font-weight: 500;
  color: #110d0c;
  font-family: "SCoreDream";
  margin-bottom: 32px;
}
.CleanTech .product-list li .buy-btn {
  width: 129px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: solid 2px #d0d5de;
  background-color: #fff;
  font-weight: 500;
  font-size: 15px;
  color: #d0d5de;
  transition: all 0.3s;
}
.CleanTech .product-list li .buy-btn:hover {
  background-color: #333;
  color: #fff;
  border: solid 2px #333;
}
/* ProductDetail */

.ProductDetail .slideBtn {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  transition: all 0.3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.ProductDetail .slideBtn i {
  font-size: 24px;
  transition: all 0.3s;
}
.ProductDetail .slideBtn:hover {
  background-color: #09abbe;
}
.ProductDetail .slideBtn:hover i {
  color: #fff;
}
.ProductDetail .slidePrev {
  right: calc(100% + 48px);
}
.ProductDetail .slideNext {
  left: calc(100% + 48px);
}
.ProductDetail .h3-st1 {
  font-weight: 500;
}
.ProductDetail .section1 {
  padding: 80px 0;
  position: relative;
}
.ProductDetail .section1 .st1-bg {
  position: absolute;
  left: 0;
  bottom: 34px;
  width: 343px;
}
.ProductDetail .section1 .container {
  display: flex;
  align-items: center;
}
.ProductDetail .section1 .img-wrap {
  width: 44%;
  background-image: url(./images/product_circle.png);
  background-position: center;
  background-size: cover;
  position: relative;
}
.ProductDetail .section1 .img-wrap::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.ProductDetail .section1 .img-wrap2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 20px;
}
.ProductDetail .section1 .img-wrap img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
  border-radius: 100%;
  overflow: hidden;
  background-color: #09abbe;
}
.ProductDetail .section1 .con-box {
  width: calc(100% - 44%);
  padding-left: 80px;
}
.ProductDetail .section1 .classify1 {
  display: inline-flex;
  align-items: center;
  color: #d0d5de;
  border-radius: 20px;
  background-color: #f5f5f5;
  height: 40px;
  padding: 0 24px;
  margin-bottom: 24px;
}
.ProductDetail .section1 .txt-wrap {
  margin-bottom: 60px;
}
.ProductDetail .section1 .txt-top {
  font-size: 40px;
  font-weight: 500;
  color: #232323;
  letter-spacing: -1px;
  font-family: "SCoreDream";
  margin-bottom: 40px;
}
.ProductDetail .section1 .txt-ct {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  gap: 8px;
}
.ProductDetail .section1 .txt-ct span {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #d0d5de;
}
.ProductDetail .section1 .txt-ct span i {
  color: #fff;
}
.ProductDetail .section1 .txt-ct p {
  color: #d0d5de;
}
.ProductDetail .section1 .txt-bt {
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #aaa;
}
.ProductDetail .section1 .buy-btn {
  width: 187px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #09abbe;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  color: #fff;
  padding: 0 24px;
  border-radius: 28px;
}
.ProductDetail .section1 .buy-btn i {
  color: #fff;
  font-size: 19px;
  letter-spacing: -0.48px;
  color: #fff;
}
.ProductDetail .section2 {
  background-color: #f5f5f5;
  padding: 56px 0;
}
.ProductDetail .section2 .swiper-wrap {
  position: relative;
}
.ProductDetail .section2 .swiper-slide {
  height: 400px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.ProductDetail .section2 .swiper-slide .img-wrap {
  width: 100%;
  height: 100%;
}
.ProductDetail .section2 .swiper-slide .img-wrap img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.ProductDetail .section2 .swiper-slide .txt-wrap {
  text-align: center;
  display: flex;
  padding: 24px;
  gap: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: #00000080;
  opacity: 0;
  transition: all 0.3s;
}
.ProductDetail .section2 .swiper-slide .txt-wrap:hover {
  opacity: 1;
}
.ProductDetail .section2 .swiper-slide .txt-wrap p {
  color: #fff;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.45px;
}
.ProductDetail .section2 .swiper-slide .txt-wrap button {
  font-size: 16px;
  display: flex;
  width: 160px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #09abbe;
  color: #fff;
  letter-spacing: -0.4px;
}
.ProductDetail .section3 {
  padding: 80px 0;
}
.ProductDetail .section4 {
  background-color: #f5f5f5;
  padding: 80px 0;
}
.ProductDetail .section4 .h3-st1 {
  margin-bottom: 56px;
}
.ProductDetail .section4 .swiper-wrap {
  position: relative;
}
.ProductDetail .section4 .swiper-slide {
  position: relative;
}
.ProductDetail .section4 .swiper-slide::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 141%;
}
.ProductDetail .section4 .swiper-slide .img-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ProductDetail .section4 .swiper-slide .img-wrap img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.ProductDetail .section5 {
  padding: 80px 0 120px;
}
.ProductDetail .section5 .h3-st1 {
  margin-bottom: 56px;
}
.ProductDetail .section5 .faq-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}
.ProductDetail .section5 .question-wrap {
  min-height: 96px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 40px;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}
.ProductDetail .section5 .question-wrap .q-wrap {
  color: #09abbe;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: solid 2px #09abbe;
  background-color: #fff;
}
.ProductDetail .section5 .question-wrap p {
  width: calc(100% - 48px - 40px);
  padding: 16px 24px;
  font-size: 18px;
  letter-spacing: -0.45px;
}
.ProductDetail .section5 .question-wrap .plus-wrap {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #09abbe;
}
.ProductDetail .section5 .question-wrap .plus-wrap i {
  font-size: 20px;
  color: #fff;
}
.ProductDetail .section5 .answer-wrap {
  height: 0;
  overflow: hidden;
}
.ProductDetail .section5 .answer-wrap.open {
  height: auto;
}
.ProductDetail .section5 .answer-box {
  display: flex;
  padding: 32px 40px;
  background-color: #f5f5f5;
}
.ProductDetail .section5 .answer-box .a-wrap {
  color: #c9c9c9;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: solid 2px #c9c9c9;
  background-color: #fff;
}
.ProductDetail .section5 .answer-box p {
  width: calc(100% - 48px);
  padding-left: 24px;
  line-height: 1.88;
  letter-spacing: -0.4px;
}
.ProductDetail .section5 .inventory-btn {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  width: 218px;
  height: 64px;
  border-radius: 32px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.13);
  background-color: #a8a8a8;
  margin: 0 auto;
}
.ProductDetail .inqu-btn {
  bottom: 17%;
  right: 40px;
  position: fixed;
  width: 186px;
  height: 186px;
  background-image: url(./images/inqu_btn.png);
}

@media screen and (max-width: 768px) {
  /* Clean Tech */
  .CleanTech .sub-con {
    padding-top: 40px;
    margin-bottom: 60px;
  }
  .CleanTech .search-wrap {
    height: 40px;
    margin: 0 auto 40px;
    border-radius: 20px;
    padding-left: 24px;
    max-width: 360px;
  }
  .CleanTech .search-wrap input {
    width: calc(100% - 40px);
    font-size: 12px;
    letter-spacing: -0.2px;
  }
  .CleanTech .search-wrap button {
    width: 40px;
  }
  .CleanTech .search-wrap button i {
    font-size: 16px;
  }
  .CleanTech .tab-btn {
    gap: 12px;
    margin-bottom: 40px;
    flex-flow: wrap;
  }
  .CleanTech .tab-btn li {
    width: calc((100% - 12px * 2) / 3);
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    border: 1px solid #c1ccce;
  }
  .CleanTech .product-list {
    gap: 32px;
    margin-bottom: 40px;
  }
  .CleanTech .product-list li {
    width: 100%;
  }
  .CleanTech .product-list li .title-wrap {
    padding: 24px;
  }
  .CleanTech .product-list li .classify {
    margin-bottom: 16px;
  }
  .CleanTech .product-list li .classify span {
    width: 27px;
    height: 27px;
  }
  .CleanTech .product-list li .classify p {
    width: calc(100% - 27px - 8px);
    font-size: 14px;
  }
  .CleanTech .product-list li .title {
    font-size: 16px;
    margin-bottom: 24px;
  }
  .CleanTech .product-list li .buy-btn {
    width: 100px;
    height: 36px;
    border: solid 1px #d0d5de;
    font-size: 14px;
  }

  /* ProductDetail */
  .ProductDetail .slideBtn {
    display: none;
  }
  .ProductDetail .h3-st1 {
    font-weight: 500;
  }
  .ProductDetail .section1 {
    padding: 40px 0;
  }
  .ProductDetail .section1 .st1-bg {
    display: none;
  }
  .ProductDetail .section1 .container {
    flex-flow: wrap;
    gap: 40px;
  }
  .ProductDetail .section1 .img-wrap {
    width: 100%;
  }
  .ProductDetail .section1 .con-box {
    width: 100%;
    padding-left: 0;
  }
  .ProductDetail .section1 .classify1 {
    border-radius: 16px;
    height: 32px;
    padding: 0 16px;
    margin-bottom: 16px;
  }
  .ProductDetail .section1 .txt-wrap {
    margin-bottom: 32px;
  }
  .ProductDetail .section1 .txt-top {
    font-size: 18px;
    margin-bottom: 24px;
  }
  .ProductDetail .section1 .txt-ct {
    gap: 8px;
    margin-bottom: 24px;
  }
  .ProductDetail .section1 .txt-ct span {
    width: 24px;
    height: 24px;
  }
  .ProductDetail .section1 .txt-bt {
    font-size: 14px;
  }
  .ProductDetail .section1 .buy-btn {
    width: 132px;
    height: 40px;
    font-size: 12px;
    padding: 0 16px;
    border-radius: 20px;
  }
  .ProductDetail .section2 {
    padding: 40px 0;
  }
  .ProductDetail .section2 .swiper-slide {
    height: 260px;
    width: 260px;
  }
  .ProductDetail .section2 .swiper-slide .txt-wrap {
    padding: unset;
  }
  .ProductDetail .section2 .swiper-slide .txt-wrap a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .ProductDetail .section2 .swiper-slide .txt-wrap:hover {
    opacity: 0;
  }
  .ProductDetail .section3 {
    padding: 40px 0;
  }
  .ProductDetail .section4 {
    padding: 40px 0;
  }
  .ProductDetail .section4 .h3-st1 {
    margin-bottom: 32px;
  }
  .ProductDetail .section5 {
    padding: 40px 0 60px;
  }
  .ProductDetail .section5 .h3-st1 {
    margin-bottom: 32px;
  }
  .ProductDetail .section5 .faq-wrap {
    margin-bottom: 40px;
  }
  .ProductDetail .section5 .question-wrap {
    min-height: 80px;
    padding: 0 20px;
  }
  .ProductDetail .section5 .question-wrap .q-wrap {
    font-size: 16px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
  .ProductDetail .section5 .question-wrap p {
    width: calc(100% - 40px - 32px);
    padding: 8px 16px;
    font-size: 16px;
    letter-spacing: -0.2px;
  }
  .ProductDetail .section5 .question-wrap .plus-wrap {
    width: 32px;
    height: 32px;
  }
  .ProductDetail .section5 .answer-box {
    padding: 32px 20px;
  }
  .ProductDetail .section5 .answer-box .a-wrap {
    font-size: 16px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
  .ProductDetail .section5 .answer-box p {
    width: calc(100% - 40px);
    padding-left: 16px;
    font-size: 14px;
    word-break: break-all;
  }
  .ProductDetail .section5 .inventory-btn {
    width: 160px;
    height: 48px;
    border-radius: 24px;
  }
  .ProductDetail .inqu-btn {
    bottom: 17%;
    right: 20px;
    width: 72px;
    height: 72px;
    background-size: cover;
  }
}

@media screen and (max-width: 480px) {
}

/* ==================================================================================
    Pr
=====================================================================================*/
.PR .banner-img {
  background-image: url(./images/sub-top-04.jpg);
}

.News .sub-map .sub-menu3 {
  display: unset;
}

/* Activity */
.Activity .section1 {
  padding: 80px 0;
}
.Activity .section1 .img-wrap {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
}
.Activity .section2 {
  padding: 80px 0 120px;
  background-color: #f5f5f5;
}
.Activity .section2 .h3-st2 {
  margin-bottom: 56px;
}
.Activity .section2 .tab-wrap {
  display: flex;
  border-radius: 28px;
  background-color: #fff;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 56px;
}
.Activity .section2 .tab-wrap li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
  height: 56px;
  border-radius: 28px;
  background-color: #fff;
  color: #c1ccce;
  font-size: 17px;
  font-weight: 500;
  font-family: "SCoreDream";
  cursor: pointer;
  transition: all 0.3s;
}
.Activity .section2 .tab-wrap li.now {
  background-color: #09abbe;
  color: #fff;
}
.Activity .section2 .tab-wrap li:hover {
  background-color: #09abbe;
  color: #fff;
}
.Activity .section2 .img-list {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 80px;
}
.Activity .section2 .img-list li {
  width: calc((100% - 80px) / 3);
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}
.Activity .section2 .img-list li::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 68%;
}
.Activity .section2 .img-list li .img-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.Activity .section2 .img-list li .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.Activity .section2 .img-list li .txt-wrap {
  display: flex;
  gap: 8px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: #00000080;
  opacity: 0;
  transition: all 0.3s;
  cursor: pointer;
}
.Activity .section2 .img-list li .txt-wrap:hover {
  opacity: 1;
}
.Activity .section2 .img-list li .txt-wrap p {
  font-size: 18px;
  letter-spacing: -0.45px;
  color: #fff;
}
.Activity .section2 .add-btn {
  margin: 0 auto;
  font-size: 16px;
  color: #09abbe;
  font-weight: bold;
  width: 218px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.13);
  border: solid 2px #09abbe;
}
.Activity .pop-up {
  display: none;
  z-index: 999;
  position: fixed;
  background-color: #000000bf;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
}
.Activity .pop-up.open {
  display: flex;
}
.Activity .pop-up .img-wrap {
  width: calc(100% - 40px);
  max-width: 900px;
  cursor: pointer;
}

/* News */
.News .sub-con {
  padding: 80px 0 120px;
}
.News .tab-wrap {
  display: flex;
  width: 100%;
  max-width: 320px;
  margin: 0 auto 40px;
  background-color: #f5f5f5;
  border-radius: 28px;
}
.News .tab-wrap li {
  width: 50%;
  height: 56px;
  color: #c1ccce;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 28px;
  transition: all 0.3s;
}
.News .tab-wrap li.now {
  background-color: #09abbe;
  color: #fff;
}
.News .tab-wrap li:hover {
  background-color: #09abbe;
  color: #fff;
}
.News .list-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.News .search-wrap {
  display: flex;
  width: 400px;
  height: 56px;
  border-radius: 28px;
  box-shadow: inset 0 3px 4px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px #b3b8c0;
  overflow: hidden;
}
.News .search-wrap input {
  width: calc(100% - 56px);
  border: unset;
  padding-left: 24px;
  background-color: transparent;
}
.News .search-wrap button {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  border-radius: 28px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #09abbe;
}
.News .search-wrap button i {
  font-size: 24px;
  color: #fff;
}
.News .News-list {
  border-top: 1px solid #232323;
  margin-bottom: 56px;
}
.News .News-list li {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  padding: 24px 40px;
  justify-content: space-between;
  transition: all 0.3s;
}
.News .News-list li .date {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.News .News-list li .date .day {
  font-size: 56px;
  font-weight: bold;
  letter-spacing: -1.4px;
  color: #aaa;
  line-height: normal;
  transition: all 0.3s;
}
.News .News-list li .date .year {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.45px;
  color: #aaa;
  line-height: normal;
  transition: all 0.3s;
}
.News .News-list li .title-wrap {
  width: calc(100% - 70px - 160px);
  padding-left: 72px;
  display: flex;
  gap: 16px;
}
.News .News-list li .title-wrap.notify::before {
  content: "공지";
  letter-spacing: -0.4px;
  color: #09abbe;
  font-weight: bold;
  width: 56px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: solid 1px #09abbe;
  background-color: #fff;
  transition: all 0.3s;
}
.News .News-list li .txt-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.News .News-list li .title-wrap.notify .txt-wrap {
  width: calc(100% - 56px - 16px);
}
.News .News-list li .txt-wrap .title {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 24px;
  line-height: normal;
  letter-spacing: -0.6px;
  color: #232323;
  font-weight: bold;
}
.News .News-list li .txt-wrap .con {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  line-height: normal;
  letter-spacing: -0.4px;
  color: #232323;
}
.News .News-list li .hit {
  display: flex;
  align-items: center;
}
.News .News-list li .hit .mb-date {
  display: none;
}
.News .News-list li .hit i {
  font-size: 24px;
  letter-spacing: -0.6px;
  color: #232323;
  margin-right: 4px;
}
.News .News-list li .hit .num {
  font-size: 18px;
  color: #232323;
  font-weight: bold;
}
.News .News-list li .hit .plus {
  width: 40px;
  height: 40px;
  background-image: url(./images/point.png);
  margin-left: 72px;
}
.News .News-list li:hover {
  background-color: #f5f5f5;
}
.News .News-list li:hover .title-wrap.notify::before {
  color: #fff;
  background-color: #09abbe;
}
.News .News-list li:hover .date .day {
  color: #09abbe;
}
.News .News-list li:hover .date .year {
  color: #09abbe;
}
.News .noData-wrap {
  padding-top: 80px;
}

/* NewsDetail */
.NewsDetail .sub-con {
  padding: 80px 0 120px;
}
.NewsDetail .title-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 104px;
  padding: 16px 0;
  border-top: 1px solid #232323;
  border-bottom: 1px solid #d0d5de;
  margin-bottom: 16px;
}
.NewsDetail .title-wrap h4 {
  text-align: center;
  font-family: "SCoreDream";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
  color: #454545;
  line-height: 1.5;
}
.NewsDetail .date-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 32px;
}
.NewsDetail .con-wrap {
  padding: 80px 0;
}
.NewsDetail .attachedFile {
  border: solid 0.6px #aaa;
  margin-bottom: 40px;
}
.NewsDetail .attachedFile .attachedFile-top {
  width: 100%;
  height: 56px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 4px;
}
.NewsDetail .attachedFile .attachedFile-top i {
  color: #454545;
}
.NewsDetail .attachedFile .attachedFile-top span {
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #454545;
}
.NewsDetail .attachedFile .file-list {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.NewsDetail .attachedFile .file-list li {
  display: flex;
  gap: 4px;
}
.NewsDetail .attachedFile .file-list li i {
  color: #444;
}
.NewsDetail .attachedFile .file-list li a {
  cursor: pointer;
  color: #444;
  letter-spacing: -0.4px;
}
.NewsDetail .inventory-btn {
  width: 148px;
  height: 48px;
  border-radius: 24px;
  font-weight: 400;
  letter-spacing: -0.4px;
  color: #fff;
  font-size: 16px;
  background-color: #09abbe;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 40px;
}
.NewsDetail .turn-wrap > div {
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-top: 1px solid #aaa;
  height: 54px;
}
.NewsDetail .turn-wrap > div:last-child {
  border-bottom: 1px solid #aaa;
}
.NewsDetail .turn-wrap > div span {
  color: #444;
  letter-spacing: -0.4px;
}
.NewsDetail .turn-wrap > div span i {
  margin-left: 16px;
}
.NewsDetail .turn-wrap > div p {
  letter-spacing: -0.4px;
  color: #444;
  padding-left: 32px;
  width: calc(100% - 80px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.NewsDetail .turn-wrap > div a {
  cursor: pointer;
  letter-spacing: -0.4px;
  color: #444;
  padding-left: 32px;
  width: calc(100% - 80px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* SNS */
.SNS .section1 {
  padding-top: 80px;
  background-color: #f5f5f5;
}
.SNS .section1 .h3-st1 {
  margin-bottom: 54px;
}
.SNS .section1 .slideBtn {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  transition: all 0.3s;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
}
.SNS .section1 .slideBtn i {
  font-size: 24px;
  transition: all 0.3s;
}
.SNS .section1 .slideBtn:hover {
  background-color: #09abbe;
}
.SNS .section1 .slideBtn:hover i {
  color: #fff;
}
.SNS .section1 .slidePrev {
  right: calc(100% + 48px);
}
.SNS .section1 .slideNext {
  left: calc(100% + 48px);
}
.SNS .section1 .slide-wrap {
  position: relative;
}
.SNS .section1 .swiper-slide {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.SNS .section1 .thumbnail {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
.SNS .section1 .thumbnail::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 56%;
}
.SNS .section1 .thumbnail img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.SNS .section1 .thumbnail i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  color: #e61010;
}
.SNS .section1 .swiper-slide p {
  cursor: pointer;
  line-height: 1.5;
  letter-spacing: -0.45px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.SNS .section1 .swiper {
  padding-bottom: 90px;
}
.SNS .section1 .swiper-pagination {
  bottom: 24px;
}
.SNS .section1 .swiper-pagination .swiper-pagination-bullet {
  opacity: 0.45;
  background-color: #aaaaaa;
  z-index: 1;
}
.SNS
  .section1
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background-color: #aaaaaa;
}
.SNS .section2 {
  padding: 80px 0 120px;
}
.SNS .section2 .h3-st1 {
  margin-bottom: 54px;
}
.SNS .section2 .Instagram-list {
  display: flex;
  flex-wrap: wrap;
  gap: 50px 45px;
  margin-bottom: 55px;
}
.SNS .section2 .Instagram-list li {
  width: calc((100% - (45px * 3)) / 4);
  display: flex;
  flex-direction: column;
}
.SNS .section2 .Instagram-list li .img-wrap {
  width: 100%;
  position: relative;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d0d5de;
  cursor: pointer;
  overflow: hidden;
}
.SNS .section2 .Instagram-list li .img-wrap::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}
.SNS .section2 .Instagram-list li .img-wrap img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.SNS .section2 .Instagram-list li .img-wrap::after {
  content: "";
  position: absolute;
  top: 16px;
  left: 16px;
  width: 36px;
  height: 36px;
  background-image: url(./images/sns-icon-insta.png);
}
.SNS .section2 .Instagram-list li .title-wrap {
  padding: 24px 8px;
  border-bottom: 2px solid #e4e4e4;
}
.SNS .section2 .Instagram-list li .title-wrap p {
  cursor: pointer;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: -0.4px;
  font-weight: bold;
  color: #222;
}
.SNS .section2 .add-btn {
  font-weight: 400;
  letter-spacing: -0.4px;
  color: #09abbe;
  font-family: "SCoreDream";
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 218px;
  height: 64px;
  border-radius: 32px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.13);
  border: solid 2px #09abbe;
  margin: 0 auto;
  transition: all 0.3s;
}
.SNS .section2 .add-btn:hover {
  color: #fff;
  background-color: #09abbe;
}

@media screen and (max-width: 768px) {
  .PR .banner-img {
    background-image: url(./images/sub-top-04.jpg);
  }

  /* Activity */
  .Activity .section1 {
    padding: 40px 0;
  }
  .Activity .section2 {
    padding: 40px 0 60px;
  }
  .Activity .section2 .h3-st2 {
    margin-bottom: 32px;
  }
  .Activity .section2 .tab-wrap {
    border-radius: 24px;
    margin: 0 auto 40px;
  }
  .Activity .section2 .tab-wrap li {
    height: 48px;
    border-radius: 24px;
    font-size: 12px;
  }
  .Activity .section2 .img-list {
    gap: 24px;
    margin-bottom: 40px;
  }
  .Activity .section2 .img-list li {
    width: 100%;
    border-radius: 8px;
  }
  .Activity .section2 .img-list li .txt-wrap p {
    font-size: 16px;
  }
  .Activity .section2 .add-btn {
    font-size: 14px;
    width: 160px;
    height: 48px;
    border-radius: 24px;
  }

  /* News */
  .News .sub-con {
    padding: 40px 0 60px;
  }
  .News .tab-wrap {
    margin: 0 auto 40px;
    border-radius: 24px;
  }
  .News .tab-wrap li {
    height: 48px;
    font-size: 16px;
    border-radius: 24px;
  }
  .News .list-top {
    margin-bottom: 24px;
    flex-flow: column;
    gap: 32px;
  }
  .News .search-wrap {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    max-width: 360px;
  }
  .News .search-wrap input {
    width: calc(100% - 40px);
    font-size: 12px;
  }
  .News .search-wrap button {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
  .News .search-wrap button i {
    font-size: 20px;
  }
  .News .News-list {
    margin-bottom: 40px;
  }
  .News .News-list li {
    padding: 16px;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
  }
  .News .News-list li .date {
    flex-flow: row-reverse;
    order: 2;
  }
  .News .News-list li .date .day {
    font-size: 12px;
    display: inline-block;
    padding-left: 4px;
    position: relative;
    color: #d0d5de;
  }
  .News .News-list li .date .day::after {
    content: ".";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .News .News-list li .date .year {
    font-size: 12px;
    color: #d0d5de;
  }
  .News .News-list li .title-wrap {
    width: 100%;
    flex-flow: column;
    gap: 8px;
    padding-left: 0;
    order: 1;
  }
  .News .News-list li .title-wrap.notify::before {
    width: 44px;
    height: 24px;
    border-radius: 12px;
    font-size: 12px;
  }
  .News .News-list li .txt-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .News .News-list li .title-wrap.notify .txt-wrap {
    width: 100%;
  }
  .News .News-list li .txt-wrap .title {
    font-size: 14px;
  }
  .News .News-list li .txt-wrap .con {
    display: none;
  }
  .News .News-list li .hit {
    display: flex;
    align-items: center;
    order: 3;
  }
  .News .News-list li .hit .mb-date {
    display: none;
  }
  .News .News-list li .hit i {
    font-size: 18px;
    letter-spacing: -0.45px;
  }
  .News .News-list li .hit .num {
    font-size: 12px;
  }
  .News .News-list li .hit .plus {
    display: none;
  }

  /* NewsDetail */
  .NewsDetail .sub-con {
    padding: 40px 0 60px;
  }
  .NewsDetail .title-wrap {
    min-height: 70px;
    margin-bottom: 8px;
  }
  .NewsDetail .title-wrap h4 {
    font-size: 16px;
  }
  .NewsDetail .date-wrap {
    gap: 16px;
  }
  .NewsDetail .date-wrap p {
    font-size: 12px;
    color: #787878;
    position: relative;
  }
  .NewsDetail .date-wrap p:first-child::after {
    content: "|";
    display: block;
    position: absolute;
    right: -8px;
    top: -1px;
  }
  .NewsDetail .con-wrap {
    padding: 40px 0;
  }
  .NewsDetail .attachedFile .attachedFile-top {
    height: 48px;
  }
  .NewsDetail .attachedFile .attachedFile-top span {
    font-size: 14px;
  }
  .NewsDetail .attachedFile .file-list {
    padding: 16px;
    gap: 8px;
  }
  .NewsDetail .attachedFile .file-list li a {
    font-size: 12px;
  }
  .NewsDetail .turn-wrap > div {
    padding: 0 16px;
  }
  .NewsDetail .turn-wrap > div span {
    font-size: 14px;
  }
  .NewsDetail .turn-wrap > div span i {
    margin-left: 16px;
  }
  .NewsDetail .turn-wrap > div a {
    padding-left: 16px;
    width: calc(100% - 70px);
    font-size: 14px;
  }

  /* SNS */
  .SNS .section1 {
    padding-top: 40px;
  }
  .SNS .section1 .h3-st1 {
    margin-bottom: 32px;
  }
  .SNS .section1 .slideBtn {
    display: none;
  }
  .SNS .section1 .swiper {
    padding-bottom: 40px;
  }
  .SNS .section2 {
    padding: 40px 0 60px;
  }
  .SNS .section2 .h3-st1 {
    margin-bottom: 32px;
  }
  .SNS .section2 .Instagram-list {
    gap: 24px;
    margin-bottom: 40px;
  }
  .SNS .section2 .Instagram-list li {
    width: 100%;
  }
  .SNS .section2 .Instagram-list li .title-wrap {
    padding: 24px 0;
  }
  .SNS .section2 .add-btn {
    font-size: 14px;
    width: 160px;
    height: 48px;
    border-radius: 24px;
  }
}

/* ==================================================================================
    CONTACT US 
=====================================================================================*/
.Contact .banner-img {
  background-image: url(./images/sub-top-05.jpg);
}
/* 고객문의 */
.customer-enquiry .section1 {
  padding: 80px 0;
}
.customer-enquiry .section1 .h3-st2 {
  margin-bottom: 24px;
}
.customer-enquiry .section1 .way-wrap {
  display: flex;
  justify-content: center;
  gap: 40px;
}
.customer-enquiry .section1 .way-wrap > div {
  display: flex;
  align-items: center;
}
.customer-enquiry .section1 .way-wrap .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #aaa;
  border-radius: 100%;
  margin-right: 16px;
}
.customer-enquiry .section1 .way-wrap .icon i {
  color: #fff;
}
.customer-enquiry .section1 .way-wrap .txt {
  font-size: 18px;
  letter-spacing: -0.45px;
  color: #0f102a;
  margin-right: 24px;
  font-weight: bold;
}
.customer-enquiry .section1 .way-wrap p {
  font-size: 18px;
  color: #b7b7bf;
  letter-spacing: -0.45px;
  font-weight: 300;
}

.customer-enquiry .section2 {
  padding: 80px 0 120px;
  background-color: #f5f5f5;
}
.customer-enquiry .section2 .top-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
}
.customer-enquiry .section2 .top-wrap .q-box {
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background-color: #09abbe;
}
.customer-enquiry .section2 .top-wrap p {
  font-size: 24px;
  font-weight: 500;
  color: #232323;
  letter-spacing: -0.6px;
  font-family: "SCoreDream";
}
.customer-enquiry .section2 .selectBox2 {
  width: calc((100% - 40px) / 2);
  margin-bottom: 24px;
}
.customer-enquiry .section2 .selectBox2 .label {
  color: #b7b7bf;
  letter-spacing: -0.4px;
  width: 100%;
  border: solid 1px #aaa;
  border-radius: 8px;
  justify-content: space-between;
  padding: 0 16px;
}
.customer-enquiry .section2 .selectBox2 .label i {
  color: #b7b7bf;
}
.customer-enquiry .section2 .selectBox2 .optionList {
  background-color: #fff;
}
.customer-enquiry .section2 textarea {
  width: 100%;
  height: 300px;
  margin-bottom: 56px;
  outline: none;
  padding: 16px;
  border-radius: 8px;
  border: solid 1px #aaa;
}
.customer-enquiry .section2 .question-wrap {
  display: flex;
  gap: 24px 40px;
  flex-wrap: wrap;
  margin-bottom: 80px;
}
.customer-enquiry .section2 .question-wrap .ipt-wrap {
  width: calc((100% - 40px) / 2);
  border: solid 1px #aaa;
}
.customer-enquiry .section2 .question-wrap .ipt-wrap span.es::after {
  content: "*";
  color: #09abbe;
  margin-left: 4px;
}
.customer-enquiry .section2 .ck-wrap {
  width: calc((100% - 40px) / 2);
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.customer-enquiry .section2 .ck-wrap .ck-label p {
  color: #09abbe;
}
.customer-enquiry .section2 .ck-wrap > a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  color: #bcbcbc;
  display: flex;
  gap: 16px;
}
.customer-enquiry .section2 .ck-wrap > a::after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background-image: url(./images/point.png);
  background-position: center;
  background-size: cover;
}
.customer-enquiry .section2 .registration-btn {
  margin: 0 auto;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border-radius: 32px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.13);
  background-color: #09abbe;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 218px;
  height: 64px;
}
.customer-enquiry .section2 .pop-wrap {
  left: 0;
  top: 0;
}
/* 오시는 길 */
.Directions .section1 {
  padding: 80px 0;
}
.Directions .section1 .h3-st2 {
  margin-bottom: 40px;
}
.Directions .section1 .flex-wrap {
  display: flex;
}
.Directions .section1 .flex-wrap li {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 32px 24px 55px;
  border: solid 1px #e4e4e4;
}
.Directions .section1 .flex-wrap li .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #09abbe;
  border-radius: 100%;
}
.Directions .section1 .flex-wrap li .icon i {
  font-size: 25px;
  color: #fff;
}
.Directions .section1 .flex-wrap li h4 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  font-family: "SCoreDream";
}
.Directions .section1 .flex-wrap li p {
  font-weight: 300;
  letter-spacing: -0.4px;
  text-align: center;
}
.Directions .section1 #map {
  width: 100%;
  height: 420px;
}

.Directions .section2 {
  padding-bottom: 120px;
}
.Directions .section2 .h3-st1 {
  margin-bottom: 56px;
}
.Directions .section2 .flex-wrap {
  display: flex;
  flex-direction: column;
  border-top: 2px solid #747474;
  border-bottom: 2px solid #747474;
}
.Directions .section2 .flex-wrap li {
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #aaaaaa;
}
.Directions .section2 .flex-wrap li .img-wrap {
  width: 148px;
}
.Directions .section2 .flex-wrap li .txt-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: calc(100% - 148px - 160px);
  padding-left: 40px;
}
.Directions .section2 .flex-wrap li .txt-wrap p {
  line-height: normal;
}
.Directions .section2 .flex-wrap li .txt-wrap .oth {
  line-height: normal;
  margin-bottom: -15px;
}
.Directions .section2 .flex-wrap li .txt-wrap p:first-child {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  font-family: "SCoreDream";
}
.Directions .section2 .flex-wrap li .txt-wrap p:last-child {
  letter-spacing: -0.4px;
  color: #474747;
}
.Directions .section2 .flex-wrap li button {
  font-size: 16px;
  font-weight: bold;
  width: 160px;
  height: 48px;
  border-radius: 24px;
  background-color: #e4e4e4;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  /* 고객문의 */
  .customer-enquiry .section1 {
    padding: 40px 0;
  }
  .customer-enquiry .section1 .way-wrap {
    flex-flow: column;
    gap: 16px;
  }
  .customer-enquiry .section1 .way-wrap > div {
    width: 250px;
    margin: 0 auto;
  }
  .customer-enquiry .section1 .way-wrap .icon {
    margin-right: 8px;
  }
  .customer-enquiry .section1 .way-wrap .txt {
    font-size: 16px;
    margin-right: 8px;
  }
  .customer-enquiry .section1 .way-wrap p {
    font-size: 16px;
  }

  .customer-enquiry .section2 {
    padding: 40px 0 60px;
  }
  .customer-enquiry .section2 .top-wrap {
    margin-bottom: 24px;
  }
  .customer-enquiry .section2 .top-wrap .q-box {
    font-size: 16px;
    width: 32px;
    height: 32px;
  }
  .customer-enquiry .section2 .top-wrap p {
    font-size: 16px;
  }
  .customer-enquiry .section2 .selectBox2 {
    width: 100%;
    margin-bottom: 16px;
  }
  .customer-enquiry .section2 textarea {
    height: 120px;
    margin-bottom: 16px;
  }
  .customer-enquiry .section2 .question-wrap {
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  .customer-enquiry .section2 .ipt-wrap {
    height: 48px;
  }
  .customer-enquiry .section2 .question-wrap .ipt-wrap {
    width: 100%;
  }
  .customer-enquiry .section2 .question-wrap .ipt-wrap span {
    display: none;
  }
  .customer-enquiry .section2 .ck-wrap {
    width: 100%;
  }
  .customer-enquiry .section2 .ck-wrap > a {
    text-decoration: underline;
  }
  .customer-enquiry .section2 .ck-wrap > a::after {
    display: none;
  }
  .customer-enquiry .section2 .registration-btn {
    width: 160px;
    height: 48px;
  }
  /* 오시는 길 */
  .Directions .section1 {
    padding: 40px 0;
  }
  .Directions .section1 .h3-st2 {
    margin-bottom: 32px;
  }
  .Directions .section1 .flex-wrap {
    flex-flow: column;
  }
  .Directions .section1 .flex-wrap li {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;
    padding: 12px 16px;
  }
  .Directions .section1 .flex-wrap li .icon {
    width: 40px;
    height: 40px;
  }
  .Directions .section1 .flex-wrap li h4 {
    font-size: 14px;
  }
  .Directions .section1 .flex-wrap li p {
    font-size: 14px;
  }

  .Directions .section2 {
    padding-bottom: 60px;
  }
  .Directions .section2 .h3-st1 {
    margin-bottom: 32px;
  }
  .Directions .section2 .flex-wrap li {
    flex-flow: column;
    gap: 24px;
  }
  .Directions .section2 .flex-wrap li .img-wrap {
    width: 120px;
  }
  .Directions .section2 .flex-wrap li .txt-wrap {
    flex-direction: column;
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  .Directions .section2 .flex-wrap li .txt-wrap p {
    line-height: normal;
  }
  .Directions .section2 .flex-wrap li .txt-wrap p:first-child {
    font-size: 16px;
  }
  .Directions .section2 .flex-wrap li .txt-wrap p:last-child {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  /* sub-top */
  .sub-top {
    padding-top: 56px;
  }
  .sub-top .sub-banner {
    height: 160px;
  }
  .sub-top .sub-banner h2 {
    font-size: 24px;
  }
  .sub-top .sub-banner p {
    display: none;
  }
  .sub-top .sub-map {
    height: 40px;
  }
  .sub-top .sub-map .container {
    padding: unset;
  }
  .sub-top .sub-map .home-btn {
    width: 40px;
  }
  .sub-top .sub-map .home-btn i {
    font-size: 22px;
  }
  .sub-top .sub-map .sub-menu {
    width: calc((100% - 40px) / 2);
  }
  .sub-top .sub-map .sub-menu .show {
    padding: 0 8px;
  }
  .sub-top .sub-map .sub-menu .show p {
    font-size: 12px;
    font-weight: 500;
  }
  .sub-top .sub-map .sub-menu .show i {
    font-size: 14px;
  }
  .sub-top .sub-map .sub-menu.open .sub-menu-list {
    max-height: calc(40px * 5);
  }
  .sub-top .sub-map .sub-menu .sub-menu-list li {
    height: 40px;
  }
  .sub-top .sub-map .sub-menu .sub-menu-list li a {
    padding: 0 8px;
    font-size: 12px;
    word-break: keep-all;
    line-height: 1.33;
  }

  /* .mb-sub-menu { display: block; margin-top: 40px; }
    .mb-sub-menu ul { display: flex; border-radius: 8px; overflow: hidden; }
    .mb-sub-menu ul li { width: calc(100% / 4); height: 48px; background-color: #f5f5f5; }
    .mb-sub-menu ul li.now { background-color: #09abbe; }
    .mb-sub-menu ul li a { width: 100%; height: 100%; display: flex; text-align: center; justify-content: center; align-items: center; padding: 0 8px; font-size: 12px; line-height: 1.42; color: #232323; }
    .mb-sub-menu ul li.now a { color: #fff; } */
}
/* ==================================================================================
    공공조달
=====================================================================================*/
.Procurement .banner-img {
  background-image: url(./images/sub-top-06.png);
}
.ProcurementDetail .section1 {
  padding: 80px 0;
  position: relative;
}
.ProcurementDetail .section1 .tab-btn {
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-bottom: 56px;
}
.ProcurementDetail .section1 .tab-btn li {
  width: calc(100% / 5);
  max-width: 160px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: solid 2px #c1ccce;
  background-color: #fff;
  font-size: 17px;
  font-weight: 500;
  color: #c1ccce;
  font-family: "SCoreDream";
  cursor: pointer;
  transition: all 0.3s;
}
.ProcurementDetail .section1 .tab-btn li.now {
  background-color: #09abbe;
  color: #fff;
  border: solid 2px #09abbe;
}
.ProcurementDetail .section1 .tab-btn li:hover {
  background-color: #09abbe;
  color: #fff;
  border: solid 2px #09abbe;
}
.ProcurementDetail .section1 .st1-bg {
  position: absolute;
  left: 0;
  bottom: 34px;
  width: 343px;
}
.ProcurementDetail .section1 .container {
  display: flex;
  align-items: center;
}
.ProcurementDetail .section1 .img-wrap {
  width: 44%;
  background-image: url(./images/product_circle.png);
  background-position: center;
  background-size: cover;
  position: relative;
}
.ProcurementDetail .section1 .img-wrap::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.ProcurementDetail .section1 .img-wrap2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 20px;
}
.ProcurementDetail .section1 .img-wrap img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
  border-radius: 100%;
  overflow: hidden;
  background-color: #09abbe;
}
.ProcurementDetail .section1 .con-box {
  width: calc(100% - 44%);
  padding-left: 80px;
}
.ProcurementDetail .section1 .classify1 {
  display: inline-flex;
  align-items: center;
  color: #d0d5de;
  border-radius: 20px;
  background-color: #f5f5f5;
  height: 40px;
  padding: 0 24px;
  margin-bottom: 24px;
}
.ProcurementDetail .section1 .txt-wrap {
  margin-bottom: 60px;
}
.ProcurementDetail .section1 .txt-top {
  font-size: 40px;
  font-weight: 500;
  color: #232323;
  letter-spacing: -1px;
  font-family: "SCoreDream";
  margin-bottom: 40px;
}
.ProcurementDetail .section1 .txt-ct {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  gap: 8px;
}
.ProcurementDetail .section1 .txt-ct span {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #d0d5de;
}
.ProcurementDetail .section1 .txt-ct span i {
  color: #fff;
}
.ProcurementDetail .section1 .txt-ct p {
  color: #d0d5de;
}
.ProcurementDetail .section1 .txt-bt {
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #aaa;
}
.ProcurementDetail .section1 .buy-btn {
  width: 187px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #09abbe;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  color: #fff;
  padding: 0 24px;
  border-radius: 28px;
}
.ProcurementDetail .section1 .buy-btn i {
  color: #fff;
  font-size: 19px;
  letter-spacing: -0.48px;
  color: #fff;
}
.ProcurementDetail .section2 {
  margin-bottom: 80px;
}
.ProcurementDetail .section2 .scroll-wrap {
  width: 100%;
  margin-bottom: 24px;
}
.ProcurementDetail .section2 .table-wrap {
  width: 100%;
}
.ProcurementDetail .section2 .table-wrap table {
  width: 100%;
  text-align: center;
}
.ProcurementDetail .section2 .table-wrap table th {
  background-color: #09abbe;
  border: solid 1px #d6d6d6;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.ProcurementDetail .section2 .table-wrap table tr {
  height: 64px;
}
.ProcurementDetail .section2 .table-wrap table td {
  color: #232323;
  font-size: 18px;
  border: solid 1px #d6d6d6;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: -0.35px;
  padding: 0 16px;
}
.ProcurementDetail .section2 .table-wrap table td button {
  width: 120px;
  height: 40px;
  border-radius: 4px;
  border: solid 2px #0457f8;
  background-color: #0457f8;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  transition: all 0.3s;
}
.ProcurementDetail .section2 .table-wrap table td button:hover {
  border: solid 2px #0457f8;
  background-color: #fff;
  color: #0457f8;
}
.ProcurementDetail .section2 .caution-p {
  font-size: 18px;
  color: #232323;
  font-weight: bold;
}
.ProcurementDetail .section2 .img-wrap {
  padding-top: 80px;
}
.ProcurementDetail .section3 {
  padding-bottom: 120px;
}
.ProcurementDetail .section3 .h3-st1 {
  margin-bottom: 24px;
}
.ProcurementDetail .section3 .txt-li {
  padding-left: 64px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 64px;
}
.ProcurementDetail .section3 .txt-li li {
  position: relative;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.6px;
  color: #232323;
  padding-left: 28px;
}
.ProcurementDetail .section3 .txt-li li::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #09abbe;
  content: "";
}
.ProcurementDetail .section3 .download-btn {
  width: 230px;
  height: 56px;
  display: flex;
  margin-left: 64px;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: #09abbe;
  color: #fff;
  font-weight: 500;
  margin-bottom: 80px;
  cursor: pointer;
}
.ProcurementDetail .section3 .download-btn i {
  font-size: 20px;
  color: #fff;
}
.ProcurementDetail .section3 .img-li {
  display: flex;
  gap: 32px;
}
.ProcurementDetail .section3 .img-li li {
  width: calc((100% - 64px) / 3);
}
.ProcurementDetail .section3 .img-li li span {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8d8d8d;
  font-size: 22px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 24px;
}
.ProcurementDetail .section3 .img-li li .img-wrap {
  width: 100%;
  border: solid 1px #e4e4e4;
}
.ProcurementDetail .section3 .img-li li .img-wrap img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .ProcurementDetail .section1 {
    padding: 40px 0;
  }
  .ProcurementDetail .section1 .tab-btn {
    gap: 12px;
    margin-bottom: 40px;
    flex-flow: wrap;
  }
  .ProcurementDetail .section1 .tab-btn li {
    width: calc((100% - 12px * 2) / 3);
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    border: 1px solid #c1ccce;
  }
  .ProcurementDetail .section1 .st1-bg {
    display: none;
  }
  .ProcurementDetail .section1 .container {
    flex-flow: wrap;
    gap: 40px;
  }
  .ProcurementDetail .section1 .img-wrap {
    width: 100%;
  }
  .ProcurementDetail .section1 .con-box {
    width: 100%;
    padding-left: 0;
  }
  .ProcurementDetail .section1 .classify1 {
    border-radius: 16px;
    height: 32px;
    padding: 0 16px;
    margin-bottom: 16px;
  }
  .ProcurementDetail .section1 .txt-wrap {
    margin-bottom: 32px;
  }
  .ProcurementDetail .section1 .txt-top {
    font-size: 18px;
    margin-bottom: 24px;
  }
  .ProcurementDetail .section1 .txt-ct {
    gap: 8px;
    margin-bottom: 24px;
  }
  .ProcurementDetail .section1 .txt-ct span {
    width: 24px;
    height: 24px;
  }
  .ProcurementDetail .section1 .txt-bt {
    font-size: 14px;
  }

  .ProcurementDetail .section3 .txt-li {
    padding: unset;
  }
  .ProcurementDetail .section3 .txt-li li {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.3px;
  }
  .ProcurementDetail .section3 .download-btn {
    margin: 0 auto 40px;
    border-radius: 24px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.13);
    width: 160px;
    height: 48px;
  }
  .ProcurementDetail .section3 .img-li {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    gap: 24px;
  }
  .ProcurementDetail .section3 .img-li li {
    width: 100%;
    max-width: 320px;
  }
  .ProcurementDetail .section3 .img-li li span {
    height: 39px;
    background-color: #09abbe;
    font-size: 14px;
  }
  .ProcurementDetail .section3 .img-li li .img-wrap {
    border: solid 1px #09abbe;
  }
}

/* 팝업창 */
.pop-wrap {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(19px);
  backdrop-filter: blur(19px);
  background-color: #000000bf;
  z-index: 9999;
}
.pop-wrap.open {
  display: flex;
}
.pop-wrap .pop-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 40px);
  max-width: 640px;
  height: 100%;
  max-height: 509px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  padding: 16px;
}
.pop-wrap i {
  font-size: 48px;
  color: #09abbe;
  margin-bottom: 22px;
}
.pop-wrap span {
  font-size: 32px;
  line-height: 1.31;
  letter-spacing: -0.8px;
  font-weight: bold;
  color: #09abbe;
  margin-bottom: 48px;
}
.pop-wrap p {
  font-size: 17px;
  line-height: 1.65;
  letter-spacing: -0.43px;
  margin-bottom: 56px;
}
.pop-wrap button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 48px;
  border-radius: 24px;
  background-color: #808080;
  color: #fff;
  font-size: 18px;
  letter-spacing: -0.45px;
}

/* 개인정보 처리방침 */
.policy {
  padding: 25px 0 52px;
}
.policy .policy-top {
  border-top: 2px solid #333;
}
.policy .policy-top h2 {
  font-weight: 500;
  line-height: 2.88;
  color: #070303;
}
.policy .policy-top p {
  font-size: 10px;
  font-weight: 500;
  color: #707070;
  line-height: 1.5;
  padding: 15px 0 20px;
  border-top: 1px solid #e4e4e4;
}
.policy .policy-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 15px;
  border-top: 1px solid #e4e4e4;
  margin-bottom: 52px;
}
.policy .policy-list h3 {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #005eff;
}
.policy .policy-list h4 {
  color: #333;
}
.policy .policy-list p {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.5;
  color: #707070;
}
.policy .policy-list p span {
  color: #333;
  font-weight: 500;
}
.policy .btn-wrap {
  display: flex;
  justify-content: center;
}
.policy .btn-wrap .up-btn {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: solid 2px #005eff;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: unset;
}
.policy .btn-wrap .up-btn i {
  font-size: 26px;
  color: #005eff;
}
