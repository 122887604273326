@font-face { font-family: 'SCoreDream'; font-weight: 100;  font-style: normal;  src: url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream1.woff2) format('woff2'), url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream1.woff) format('woff'); font-display: swap;}
@font-face { font-family: 'SCoreDream'; font-weight: 200;  font-style: normal;  src: url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream2.woff2) format('woff2'), url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream2.woff) format('woff'); font-display: swap;}
@font-face { font-family: 'SCoreDream'; font-weight: 300;  font-style: normal;  src: url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream3.woff2) format('woff2'), url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream3.woff) format('woff'); font-display: swap;}
@font-face { font-family: 'SCoreDream'; font-weight: 400;  font-style: normal;  src: url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream4.woff2) format('woff2'), url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream4.woff) format('woff'); font-display: swap;}
@font-face { font-family: 'SCoreDream'; font-weight: 500;  font-style: normal;  src: url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream5.woff2) format('woff2'), url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream5.woff) format('woff'); font-display: swap;}
@font-face { font-family: 'SCoreDream'; font-weight: 600;  font-style: normal;  src: url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream6.woff2) format('woff2'), url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream6.woff) format('woff'); font-display: swap;}
@font-face { font-family: 'SCoreDream'; font-weight: 700;  font-style: normal;  src: url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream7.woff2) format('woff2'), url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream7.woff) format('woff'); font-display: swap;}
@font-face { font-family: 'SCoreDream'; font-weight: 800;  font-style: normal;  src: url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream8.woff2) format('woff2'), url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream8.woff) format('woff'); font-display: swap;}
@font-face { font-family: 'SCoreDream'; font-weight: 900;  font-style: normal;  src: url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream9.woff2) format('woff2'), url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream9.woff) format('woff'); font-display: swap;}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');
/*===========================================================================
	CSS 초기화
============================================================================*/
* {font-family: 'Noto Sans KR', 'Open Sans' ,sans-serif; box-sizing:border-box; margin:0; padding:0; font-weight: normal;}
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, em, img, strong, b, i, dl, dt, dd, ol, ul, li, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, footer, header, menu, nav, section, video, a {border:0; outline:0; font-size:100%; color:#333; line-height:1;}
body {line-height:1;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {display:block;}
ul, ol, li {list-style:none;}
strong {display: inline-block;}
a {margin:0; padding:0; font-size:100%; }
table {border-collapse:collapse; border-spacing:0;}
input, select {vertical-align:middle;}
p {line-height:1.5; word-break: keep-all;}
img {width: 100%}
input {outline:none; font-size: 16px; }
input::placeholder { font-size: 16px; font-weight: 300; letter-spacing: -0.4px; color: #b7b7bf; }
button {cursor:pointer; border: 0; outline: 0; background: none;}
a {text-decoration: none;}
textarea {resize: none; font-size: 16px; }
textarea::placeholder { font-size: 16px; font-weight: 300; letter-spacing: -0.4px; color: #b7b7bf; }
select::-ms-expand { display: none; }
.select { -o-appearance: none; -webkit-appearance: none; -moz-appearance: none; appearance: none; }
.scd { font-family: 'SCoreDream'; }
.col-group {display:flex;}
.container {max-width: 1280px; height: auto; margin: 0 auto; position: relative;}
.scroll-wrap { width: 100%; }
body { -ms-overflow-style: none; }
::-webkit-scrollbar { display: none; }
.pc { display: block; }
.mb { display: none; }
/* 버튼 */
.btn-st1{ margin: 0 auto; font-size: 16px; width: 218px; height: 64px; display: flex; justify-content: center; align-items: center; border-radius: 32px; box-shadow: 0 0 20px 0 rgb(0 0 0 / 13%); border: solid 2px #09abbe; background-color: #fff; color: #09abbe; transition: all 0.3s; font-weight: bold; }
.btn-st1:hover{ background-color: #09abbe; color: #fff; }
.btn-st1.dp-none { display: none; }
.btn-st1.inert {  }

@media screen and (max-width:1520px) {
	.container { width: 100%; padding: 0 120px;}
}

@media screen and (max-width:1280px) {
  .container{width: 100%; padding: 0 20px;}
}

@media screen and (max-width:768px) {
  .pc { display: none; }
  .mb { display: block; }
  .btn-st1 { width: 160px; height: 48px; font-size: 14px; }
}

/* selectBox2 */
.selectBox2 { position: relative; width: 100%; height: 56px; border-radius: 8px; background-color: #fff; background-size: 20px; cursor: pointer; }
.selectBox2 .label { display: flex; align-items: center; width: inherit; height: inherit; border: 0 none; outline: 0 none; padding-left: 16px; background: transparent; cursor: pointer; font-size: 16px; }
.selectBox2 .optionList { position: absolute;  top: 100%; left: 0; width: 100%; background-color: #f5f5f5; padding: 0; border-radius: 8px; overflow: auto; max-height: 0; transition: .3s ease-in; }
.selectBox2.active .optionList { max-height: calc(56px * 3 + 28px); }
.selectBox2 .optionItem { border-bottom: 1px dashed #e4e4e4; display: flex; align-items: center; padding: 0 16px; height: 56px; transition: .1s; letter-spacing: -0.4px; }
.selectBox2 .optionItem:last-child { border-bottom: 0 none; }
.selectBox2 .optionList::-webkit-scrollbar {width: 6px;}
.selectBox2 .optionList::-webkit-scrollbar-track {background: transparent; }
.selectBox2 .optionList::-webkit-scrollbar-thumb {background: #303030; border-radius: 45px;}
.selectBox2 .optionList::-webkit-scrollbar-thumb:hover {background: #303030;}

/* input */
.ipt-wrap { display: flex; background-color: #fff; border-radius: 8px; height: 56px; overflow: hidden; }
.ipt-wrap span { display: flex; width: 104px; height: 100%; align-items: center; padding-left: 24px; color: #0f102a; font-weight: bold; letter-spacing: -0.4px; }
.ipt-wrap input { width: calc(100% - 104px); border: unset; padding: 0 16px; }

/* checkbox */
.ck-input { display: none; }
.ck-label { display: flex; gap: 8px; align-items: center; }
.ck-label span { width: 16px; height: 16px; display: flex; justify-content: center; align-items: center; background-color: #fff; border-radius: 1px; }
.ck-label span i { font-size: 14px; }
.ck-label p { color: #fff; font-size: 14px; font-weight: 500; letter-spacing: -0.35px; }
.ck-input:checked + .ck-label span { background-color: #09abbe; }
.ck-input:checked + .ck-label span i { color: #fff; }
.ck-input:checked + .ck-label p { color: #09abbe; }

/* header */
.header { position: fixed; top: 0; display: flex; width: 100%; height: 80px; align-items: center; justify-content: space-between; transition: all 0.5s; z-index: 999;}
.header .logo { width: 160px; height: 39px; display: block; background-image: url(./images/LOGO-w.png); background-position: center; background-size: contain; margin-left: 40px; cursor: pointer; background-repeat: no-repeat; }
.header .header-ri { width: calc(100% - 200px); height: 100%; display: flex; justify-content: flex-end; }
.header .header-ri .nav-wrap { width: calc(100% - 170px); }
.header .header-ri .nav-wrap .mb-logo-wrap { display: none; }
.header .header-ri .hd-nav { height: 100%; width: 100%; display: flex; justify-content: flex-end; }
.header .header-ri .hd-nav > li { width: 100%; max-width: 145px; display: flex; height: 100%; position: relative; overflow: hidden; }
.header .header-ri .hd-nav > li .mb-handle { display: none; }
.header .header-ri .hd-nav > li a { display: flex; width: 100%; height: 100%; justify-content: center; align-items: center; color: #fff; cursor: pointer; text-align: center; font-size: 18px; }
.header.active { background-color: #fff; }
.header.active .logo { background-image: url(./images/LOGO.png); }
.header.active .header-ri .hd-nav > li a { color: #333; }
.header.active.no-bg { background-color: transparent; }
.header .header-ri .sm-nav { display: flex; position: absolute; top: 100%; left: 50%; width: 180px; transform: translate(-50% , 10px); background-color: #09abbe; padding: 20px; border-radius: 10px; flex-direction: column; opacity: 0; transition: opacity 300ms ease, transform 600ms ease; }
.header .header-ri .sm-nav::before { content: ''; width: 100%; height: 10px; position: absolute; bottom: 100%; left: 0; }
.header .header-ri .sm-nav li { display: flex; justify-content: center; align-items: center; width: 100%; height: 40px; color: #fff ; text-align: center; font-size: 15px; opacity: 0.7; cursor: pointer; transition: all 0.3s; border-bottom: 1px solid #fff; }
.header .header-ri .sm-nav li:last-child { border-bottom: unset; }
.header .header-ri .sm-nav li.sm-li { font-size: 12px; opacity: 0.6; letter-spacing: -0.3px; border-bottom: 1px solid #ffffff80; }
.header .header-ri .sm-nav li.sm-li.last { border-bottom: 1px solid #fff; }
.header .header-ri .hd-btn { display: flex; height: 100%; width: 85px; justify-content: center; align-items: center; cursor: pointer; }
.header .header-ri .hd-btn i { color: #fff; font-size: 30px; }
.header.active .header-ri .hd-btn i { color: #333; }
.header .nav-wrap .close-btn{ display: none; }

@media (hover: hover) {
  .header .header-ri .hd-nav > li:hover{ overflow: unset; }
  .header:hover { background-color: #fff; }
  .header:hover .logo { background-image: url(./images/LOGO.png); }
  .header:hover .header-ri .hd-nav > li a { color: #333; }
  .header:hover .header-ri .hd-btn i { color: #333; }
  .header .header-ri .sm-nav li:hover { opacity: 1; }
  .header .header-ri .hd-nav > li:hover .sm-nav { opacity: 1; transform: translate(-50% , 0); }
}

/* site-map */
.header.sitemap .nav-wrap { width: 100%; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 9999; background: rgba(0, 0, 0, 0.8); backdrop-filter: blur(24px); }
.header.sitemap .hd-nav { position: absolute; left: 50%; top: 50%; transform: translate(-50% , -50%); height: auto; justify-content: center; padding: 0 64px;}
.header.sitemap .hd-nav > li { overflow: unset; flex-direction: column; max-width: unset; width: calc(100% / 5); max-width: 240px; height: unset; border-left: 1px solid #fff; padding: 0 16px; }
.header.sitemap .hd-nav > li:last-child { border-right: 1px solid #fff; }
.header.sitemap .hd-nav > li a { pointer-events: none; height: 56px; }
.header.sitemap .hd-nav .sm-nav { position: static; transform: unset; padding: unset; width: 100%; opacity: unset; background-color: unset; }
.header.sitemap .hd-nav .sm-nav::before {display: none;}
.header.sitemap .hd-nav .sm-nav li { height: 60px; }
.header.sitemap .nav-wrap .close-btn { display: unset; position: absolute; top: 24px; right: 24px; }
.header.sitemap .nav-wrap .close-btn i { font-size: 40px; color: #fff; transition: all 0.3s; }
.header.sitemap .header-ri .hd-nav > li a { color: #fff; }
@media (hover: hover) {
  .header.sitemap:hover .hd-nav > li a { color: #fff; }
  .header.sitemap .hd-nav > li:hover .sm-nav  { transform: unset; }
  .header.sitemap .nav-wrap .close-btn:hover i { transform: rotate(0.5turn); }
}

/* footer */
.footer { display: flex; flex-direction: column; background-color: #444444; padding: 32px 60px; position: relative; }
.footer .logo { width: 181px; height: 53px; background-image: url(./images/LOGO-f.png); background-position: center; background-size: contain; background-repeat: no-repeat; margin-bottom: 24px; }
.footer .ft-content { display: flex; justify-content: space-between; }
.footer .ft-le { display: flex; flex-wrap: wrap; gap: 16px; max-width: 380px; }
.footer .ft-le li { color: #fff; font-size: 14px; letter-spacing: -0.42px;  }
.footer .ft-ri { display: flex; flex-direction: column; gap: 16px;}
.footer .ft-ri ul { display: flex; gap: 24px; justify-content: flex-end; cursor: pointer; }
.footer .ft-ri ul li { color: #fff; letter-spacing: -0.4px; }
.footer .ft-ri p { color: #fff; opacity: 0.3; font-size: 14px; letter-spacing: -0.35px; }
.footer button { width: 40px; height: 40px; background-color: #fff; border-radius: 100%; position: absolute; right: 60px; }
.footer button i { font-size: 16px; color: #232323; }
.ft-content.en { display: none; }
.main.en .ft-content { display: none; }
.main.en .ft-content.en { display: flex; }
.main .ft-content.en { display: none; }
@media screen and (max-width:768px) {
  .header { height: 56px; background-color: #fff; }
  .header .logo { background-image: url(./images/LOGO.png); width: 104px; height: 25px; margin-left: 20px; }
  .header .header-ri { margin-right: 20px; gap: 20px; }
  .header .header-ri .hd-btn { width: auto; }
  .header .header-ri .hd-btn i { color: #232323; }
  .header .header-ri .nav-wrap { display: none; }
  .header.sitemap .nav-wrap { display: unset; width: 280px; left: unset; background-color: #fff; box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.1); }
  .header.sitemap .nav-wrap .close-btn { width: 56px; height: 56px; display: flex; right: 100%; top: 0; justify-content: center; align-items: center; background-color: #09abbe; box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.1); }
  .header.sitemap .nav-wrap .close-btn i { font-size: 32px; }
  .header .header-ri .nav-wrap .mb-logo-wrap { display: flex; width: 100%; height: 56px; justify-content: center; align-items: center; }
  .header .header-ri .nav-wrap .mb-logo-wrap img{ max-width: 114px; }
  .header.sitemap .hd-nav { position: unset; transform: unset; padding: unset; justify-content: unset; flex-direction: column; max-height: calc(100vh - 56px); overflow: auto; }
  .header.sitemap .hd-nav > li { width: 100%; max-width: unset; border: unset; padding: unset; }
  .header.sitemap .hd-nav > li:last-child { border: unset; }
  .header.sitemap .hd-nav > li > a { display: none; }
  .header.sitemap .hd-nav > li .mb-handle { display: flex; width: 100%; height: 48px; justify-content: space-between; align-items: center; padding: 0 24px; cursor: pointer; border-bottom: 1px solid #efefef; }
  .header.sitemap .hd-nav .sm-nav { height: 0; overflow: hidden; }
  .header.sitemap .hd-nav .sm-nav li { padding: 0 24px; justify-content: unset; height: 40px; color: #7d7d7d; opacity: 1; background-color: #f5f5f5; }
  .header.sitemap .hd-nav .sm-nav.open { height: auto; }

  .footer { padding: 24px 20px 20px; }
  .footer .ft-content { justify-content: unset; flex-direction: column; flex-direction: column;  }
  .footer .ft-ri ul { justify-content: unset; gap: 16px; }
  .footer .ft-ri ul li { font-size: 12px; }
  .footer .logo { width: 137px; height: 39px; }
  .footer .ft-le { gap: 8px; margin-bottom: 16px; }
  .footer .ft-le li { font-size: 12px; letter-spacing: -0.36px; }
  .footer .ft-ri { gap: 8px; }
  .footer .ft-ri p { font-size: 10px; }
  .footer button { right: 20px; }



}
